<script setup>
import { computed } from 'vue'
import { usePortalsStore } from '@/stores/portals'
import { storeToRefs } from 'pinia'
import AlertMessage from '@/components/AlertMessage.vue'

const { portals } = storeToRefs(usePortalsStore())

const alertMessages = computed(() => {
  const messages = []

  if (portals.value.some(portal => portal.hasChanges)) {
    messages.push('There are unsaved changes. Save draft or publish your changes before you leave.')
  }

  return messages
})
</script>

<template>
  <Transition name="fade">
    <AlertMessage
      v-if="alertMessages.length"
      :messages="alertMessages"
      type="warning"
      :closeable="false"
    />
  </Transition>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
