<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import HomePreview from '@/components/customize/HomePreview.vue'
import { getImagesLocation } from '@/utils/portal'
import FormFieldImage from '@/components/FormFieldImage.vue'
import FormFieldError from '@/components/FormFieldError.vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())
</script>

<template>
  <div class="row">
    <div class="col-4">
      <h3 class="mb-4">Logo Assets</h3>
      <fieldset>
        <h6>Favicon</h6>
        <label>
          72x72 px in PNG file format recommended. <br>Max file size 500KB.
        </label>
        <div class="my-3"><FormFieldError :fieldModel="selectedPortal.fields.favicon" /></div>
        <FormFieldImage
          :fieldModel="selectedPortal.fields.favicon"
          name="fav"
          :location="getImagesLocation(selectedPortal)"
          :disabled="isLoading"
          :maxSize="500"
        />
      </fieldset>
      <br>
      <fieldset>
        <h6>Main Logo</h6>
        <label>
          72px height in EPS or SVG file format recommended. <br>Max file size 1MB.
        </label>
        <div class="my-3"><FormFieldError :fieldModel="selectedPortal.fields.mainLogo" /></div>
        <FormFieldImage
          :fieldModel="selectedPortal.fields.mainLogo"
          name="logo"
          :location="getImagesLocation(selectedPortal)"
          :disabled="isLoading"
          :maxSize="1000"
        />
      </fieldset>
    </div>
    <div class="col-8">
      <HomePreview />
    </div>
  </div>
</template>
