<script setup>
import { storeToRefs } from 'pinia'
import { useClientsStore } from '@/stores/clients'
//import FormFieldCheckbox from '@/components/FormFieldCheckbox.vue'
import FormFieldNumber from '@/components/FormFieldNumber.vue'
import FormFieldError from '@/components/FormFieldError.vue'

const { isLoading, isSaving, selectedClient } = storeToRefs(useClientsStore())
</script>

<template>
  <div>
<!-- TODO: show this fieldset again when we have deprecated these fields on the express returns tab on the portal settings -->
<!--    <fieldset>-->
<!--      <label class="form-label">The following is available in your Returns Portal (Optional):</label>-->
<!--      <div class="form-check mt-2">-->
<!--        <FormFieldCheckbox-->
<!--          class="form-check-input"-->
<!--          role="switch"-->
<!--          :fieldModel="selectedClient.fields.dropOffEnabled"-->
<!--          :disabled="isLoading || isSaving"-->
<!--        />-->
<!--        <label class="form-check-label">Express Returns Drop-offs</label>-->
<!--      </div>-->
<!--      <div class="form-check mt-2">-->
<!--        <FormFieldCheckbox-->
<!--          class="form-check-input"-->
<!--          type="checkbox"-->
<!--          role="switch"-->
<!--          :fieldModel="selectedClient.fields.homePickupEnabled"-->
<!--          :disabled="isLoading || isSaving"-->
<!--        />-->
<!--        <label class="form-check-label">Express Returns Home Pick-ups</label>-->
<!--      </div>-->
<!--    </fieldset>-->
    <fieldset class="mt-5">
      <h6>RMA Eligibility</h6>
      <div class="d-flex align-items-center mt-3 me-3">
        Customers can return a max of
        <FormFieldNumber
          class="form-control number-input mx-2"
          placeholder="0"
          :fieldModel="selectedClient.fields.returnQuantityThreshold"
          :disabled="isLoading || isSaving"
        />
        items per RMA *
      </div>
      <FormFieldError :fieldModel="selectedClient.fields.returnQuantityThreshold" />
      <div class="d-flex align-items-center mt-3">
        Customers can return a max of
        <FormFieldNumber
          :fieldModel="selectedClient.fields.returnPriceThreshold"
          class="form-control number-input mx-2"
          placeholder="0.00"
          :disabled="isLoading || isSaving"
        />
        in total value per RMA *
      </div>
      <FormFieldError :fieldModel="selectedClient.fields.returnPriceThreshold" />
    </fieldset>
<!-- TODO: show this fieldset again when we have deprecated these fields on the express returns tab on the portal settings -->
<!--    <fieldset class="mt-5">-->
<!--      <h6>Drop-offs</h6>-->
<!--      <div class="d-flex align-items-center mt-3 me-3">-->
<!--        Show customers drop-off locations within-->
<!--        <FormFieldNumber-->
<!--          :fieldModel="selectedClient.fields.dropOffsDistanceThreshold"-->
<!--          class="form-control number-input mx-2"-->
<!--          placeholder="0.00"-->
<!--          :disabled="isLoading || isSaving"-->
<!--        />-->
<!--        miles of their address *-->
<!--      </div>-->
<!--      <FormFieldError :fieldModel="selectedClient.fields.dropOffsDistanceThreshold" />-->
<!--    </fieldset>-->
  </div>
</template>

<style lang="scss" scoped>
.number-input {
  width: 72px;
  text-align: center;
}
</style>
