
    import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  style: {"width":"1em","height":"1em","fill":"currentColor"}
}
const _hoisted_2 = ["xlink:href"]

 function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("use", {
      "xlink:href": '#' + _ctx.name
    }, null, 8, _hoisted_2)
  ]))
}

export default {
  name: "BootstrapIcon",
  props: {
    name: {
      type: String,
      required: true
    }
  },
  render
}
    
export const svgNames = ["bs-icon-arrow-clockwise","bs-icon-arrow-left","bs-icon-arrow-right","bs-icon-box-arrow-up-right","bs-icon-chevron-expand","bs-icon-chevron-right","bs-icon-lock-fill","bs-icon-x-lg","bs-icon-x"]

    
    
   