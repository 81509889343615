<script setup>
import { computed, watch, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import { sortBy, uniq } from 'lodash'
import { WEB_FONTS } from '@/constants'
import { getExtension } from '@/utils/file'
import FileListUploader from '@/components/FileListUploader.vue'
import HomePreview from '@/components/customize/HomePreview.vue'
import { getFontsLocation } from '@/utils/portal'
import { getFileUrl } from '@/utils/file'
import FormFieldSelect from '@/components/FormFieldSelect.vue'
import FormFieldError from '@/components/FormFieldError.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())

const brandFontAssetsNames = computed(() => {
  return uniq((selectedPortal.value.fields.brandFontAssets.inputValue || []).map(({ name }) => name))
})

const brandFontOptions = computed(() => {
  const options = brandFontAssetsNames.value.map(value => ({
    label: value.replace('-',' ').replace('_',' '),
    value
  }))

  return sortBy(options, ({ label }) => label)
})

watch(brandFontOptions, newBrandFontOptions => {
  const fieldKeys = [
    'mainHeaderBrandFontFamily',
    'subHeaderBrandFontFamily',
    'bodyBrandFontFamily'
  ]
  const { fields } = selectedPortal.value
  const newValues = newBrandFontOptions.map(({ value }) => value)
  fieldKeys.forEach((fieldKey) => {
    if (!newValues.includes(fields[fieldKey].inputValue)) {
      fields[fieldKey].inputValue = null
    }
  })
})

const brandFontAssets = computed(() => {
  return selectedPortal.value.fields.brandFontAssets.inputValue || []
})

watch(brandFontAssets, loadFonts)

onMounted(() => { loadFonts(brandFontAssets.value) })

function loadFonts(fonts) {
  fonts.forEach(loadFont)
}

async function loadFont(file) {
  const { name, filename } = file
  const source = getExtension(filename) === 'ttf'
    ? `url('${getFileUrl(file)}') format('truetype')`
    : `url('${getFileUrl(file)}')`
  const font = new FontFace(name, source)
  try {
    const loadedFont = await font.load()
    document.fonts.add(loadedFont)
  } catch (error) {
    console.log(error)
  }
}
</script>

<template>
  <div class="row">
    <div class="col-4">
      <fieldset class="mb-5">
        <h3>Font Family</h3>
        <p>
          Upload your brand font and select a backup web font in case your brand font won’t render on certain web browsers. A default sans-serif font will be used if you don’t make any selections.
        </p>
        <h6>Brand Font</h6>
        <p>
          Upload each font weight file individually (e.g. Bold, Regular, etc). OTF or TTF files only. (Optional)
        </p>
        <div>
          <div class="invalid-feedback d-block mb-3">
            {{ selectedPortal.fields.brandFontAssets.error }}
          </div>
          <FileListUploader
            v-model="selectedPortal.fields.brandFontAssets.inputValue"
            :location="getFontsLocation(selectedPortal)"
            :disabled="isLoading || !selectedPortal.fields.brandFontAssets.editable"
            :accept="['.otf', '.ttf']"
          />
        </div>
      </fieldset>
      <fieldset class="w-75">
        <h3>Text Styling</h3>
        <p>
          Please enter the preferred font style and color for the following text groups.
        </p>
        <h6>Headers</h6>
        <div class="form-group mt-3">
          <label class="form-label">Main Header Brand Font Style (Optional)</label>
          <FormFieldSelect
            class="form-select"
            :fieldModel="selectedPortal.fields.mainHeaderBrandFontFamily"
            :disabled="isLoading || !brandFontOptions.length"
          >
            <option :value="null">Select Brand Font Family</option>
            <option
              v-for="option in brandFontOptions" :key="option.value" :value="option.value">
              {{ option.label }}
            </option>
          </FormFieldSelect>
          <FormFieldError :fieldModel="selectedPortal.fields.mainHeaderBrandFontFamily" />
        </div>
        <div class="form-group mt-3">
          <label class="form-label">Main Header Backup Web Style (Optional)</label>
          <FormFieldSelect
            class="form-select"
            :fieldModel="selectedPortal.fields.mainHeaderWebFontFamily"
            :disabled="isLoading"
          >
            <option :value="null">Select Web Font Family</option>
            <option v-for="webFontFamily in WEB_FONTS" :key="webFontFamily" :value="webFontFamily">
              {{ webFontFamily }}
            </option>
          </FormFieldSelect>
          <FormFieldError :fieldModel="selectedPortal.fields.mainHeaderWebFontFamily" />
        </div>
        <div class="form-group mt-3">
          <label class="form-label">Sub-header Brand Font Style (Optional)</label>
          <FormFieldSelect
            class="form-select"
            :fieldModel="selectedPortal.fields.subHeaderBrandFontFamily"
            :disabled="isLoading || !brandFontOptions.length"
          >
            <option :value="null">Select Brand Font Family</option>
            <option v-for="option in brandFontOptions" :key="option.value" :value="option.value">
              {{ option.label }}
            </option>
          </FormFieldSelect>
          <FormFieldError :fieldModel="selectedPortal.fields.subHeaderBrandFontFamily" />
        </div>
        <div class="form-group mt-3">
          <label class="form-label">Sub-header Backup Web Style (Optional)</label>
          <FormFieldSelect
            class="form-select"
            :fieldModel="selectedPortal.fields.subHeaderWebFontFamily"
            :disabled="isLoading"
          >
            <option :value="null">Select Web Font Family</option>
            <option v-for="webFontFamily in WEB_FONTS" :key="webFontFamily" :value="webFontFamily">
              {{ webFontFamily }}
            </option>
          </FormFieldSelect>
          <FormFieldError :fieldModel="selectedPortal.fields.subHeaderWebFontFamily" />
        </div>
        <div class="form-group mt-3">
          <label class="form-label">Header Color (Hex Code Value) (Optional)</label>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.headerColor"
            :disabled="isLoading"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.headerColor" />
        </div>
        <br>
        <h6>Body</h6>
        <div class="form-group mt-3">
          <label class="form-label">Body Brand Font Style (Optional)</label>
          <FormFieldSelect
            class="form-select"
            :fieldModel="selectedPortal.fields.bodyBrandFontFamily"
            :disabled="isLoading || !brandFontOptions.length"
          >
            <option :value="null">Select Brand Font Family</option>
            <option v-for="option in brandFontOptions" :key="option.value" :value="option.value">
              {{ option.label }}
            </option>
          </FormFieldSelect>
          <FormFieldError :fieldModel="selectedPortal.fields.bodyBrandFontFamily" />
        </div>
        <div class="form-group mt-3">
          <label class="form-label">Body Backup Web Style (Optional)</label>
          <FormFieldSelect
            class="form-select"
            :fieldModel="selectedPortal.fields.bodyWebFontFamily"
            :disabled="isLoading"
          >
            <option :value="null">Select Web Font Family</option>
            <option v-for="webFontFamily in WEB_FONTS" :key="webFontFamily" :value="webFontFamily">
              {{ webFontFamily }}
            </option>
          </FormFieldSelect>
          <FormFieldError :fieldModel="selectedPortal.fields.bodyWebFontFamily" />
        </div>
        <div class="form-group mt-3">
          <label class="form-label">Body Color (Hex Code Value) (Optional)</label>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.bodyColor"
            :disabled="isLoading"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.bodyColor" />
        </div>
      </fieldset>
    </div>
    <div class="col-8">
      <HomePreview />
    </div>
  </div>
</template>
