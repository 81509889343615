<script setup>
import { ref, computed } from 'vue'
import { getName, getExtension, buildFileObject } from '@/utils/file'
import { uploadFile } from '@/api'
import { useAlertStore } from '@/stores/alert'

const props = defineProps({ 
  modelValue: Object,
  name: String,
  location: String,
  maxSize: {
    type: Number,
    default() {
      return 0
    }
  },
  accept: {
    type: Array,
    default() {
      return ['*']
    } 
  },
  timestamp: Boolean,
  disabled: Boolean
})

const emit = defineEmits(['update:modelValue'])

const alert = useAlertStore()

const fileInput = ref(null)
const isLoading = ref(false)

const uploadedFile = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

function onClickUpload() {
  fileInput.value.click()
}

function onClickRemove() {
  fileInput.value.value = null
  uploadedFile.value = null
}
const error = ref(null)

function validateFile(file) {
  error.value = null

  if (props.maxSize > 0 && file.size > (props.maxSize * 1024)) {
    error.value = 'File Size is too large.'
  }

  return !!error.value
}

async function handleFileChange(event) {
    const file = event.target.files[0]
    const filename = `${props.location}/${buildFileName(file.name)}`

    if (validateFile(file)) return

    isLoading.value = true
    try {
      const { data } = await uploadFile(file, filename)
      uploadedFile.value = buildFileObject(data)
    } catch (error) {
      alert.error('An error occurred while uploading the file.')
    } finally {
      isLoading.value = false
    }
}

function buildFileName(filename) {
  let name = props.name || getName(filename)

  if (props.timestamp) {
    name = `${name}_${Date.now()}`
  }

  return `${name}.${getExtension(filename)}`
}
</script>

<template>
  <div>
    <div class="my-3 invalid-feedback d-block">
      {{ error }}
    </div>
    <input
      type="file"
      ref="fileInput"
      @change="handleFileChange"
      :accept="accept.join(',')"
    />
    <button
      v-if="uploadedFile"
      type="button"
      class="btn btn-outline-primary w-100"
      :disabled="disabled || isLoading"
      @click="onClickRemove"
    >
      <span v-if="isLoading" class="spinner-border spinner-border-sm me-1"></span>
      REMOVE
    </button>
    <button
      v-else
      type="button"
      class="btn btn-primary w-100"
      :disabled="disabled || isLoading"
      @click="onClickUpload"
    >
      <span v-if="isLoading" class="spinner-border spinner-border-sm me-1"></span>
      UPLOAD
    </button>
  </div>
</template>

<style lang="scss" scoped>
input[type="file"] {
    display: none;
}
</style>
