import axios from 'axios'
import { checkToken, redirectToLogin, logout } from '@/utils/auth'

export const axiosApi = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
})

export const uploadFile = async (file, filename = file.name) => {
  const form = new FormData()
  form.append('file', file)
  form.append('filename', filename)
  return await axiosApi.post('/upload/', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const deleteFile = async (filename) => {
  return await axiosApi.delete(`/upload/?filename=${encodeURIComponent(filename)}`)
}

axiosApi.interceptors.response.use((response) => {
  return response
}, (error) => {
  if ([403, 401].includes(error.response.status)) {
      logout()
  }
  return Promise.reject(error)
})

axiosApi.interceptors.request.use(
  (config) => {
    const token = checkToken()

    if (!token) { return redirectToLogin() }

    config.headers.Authorization =  token ? `Bearer ${token}` : ''

    return config
  }
)
