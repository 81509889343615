import { ref, computed, toRaw } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { axiosApi } from '@/api'
import { useUserStore } from '@/stores/user'
import { useAlertStore } from '@/stores/alert'
import { buildFormState } from '@/utils/form'
import validationSchema from '@/validation_schemas/portal'
import fieldsMapping from '@/stores/portals/fields_mapping'
import { getPortalName } from '@/utils/portal'

export const usePortalsStore = defineStore('portals', () => {
  const alert = useAlertStore()
  const {
    editablePortalFieldPaths,
    canSavePortalDraft,
    canPublishPortal
  } = storeToRefs(useUserStore())

  const isLoading = ref(false)
  const isSaving = ref(false)
  const isPublishing = ref(false)
  const portals = ref([])
  const selectedPortalIndex = ref(null)
  const selectedPortal = computed(() => {
    const index = selectedPortalIndex.value
    if (index > -1 && index < portals.value.length) {
      return portals.value[index]
    }
    return null
  })

  async function fetchPortals(clientId) {
    isLoading.value = true
    try {
      const { data } = await axiosApi.get(`/portal/?client_id=${clientId}`)
      portals.value = data.map(buildPortalItem)
    } catch (error) {
      alert.error('An error occurred while fetching the portals.')
      console.log(error)
    } finally {
      isLoading.value = false
    }
  }

  async function createPortal(portalData) {
    isLoading.value = true
    isSaving.value = true
    try {
      const { data } = await axiosApi.post('/portal/', portalData)
      portals.value = portals.value.concat(buildPortalItem(data))
      alert.success('First step of portal creation done! Please complete required action to finalize the process.')
    } catch (error) {
      alert.error('The initial portal creation step failed. Please try again')
      console.log(error)
    } finally {
      isLoading.value = false
      isSaving.value = false
    }
  }

  async function updatePortal(portalFormItem) {
    const { values } = portalFormItem
    const { identifier } = values
    isLoading.value = true
    isSaving.value = true
    try {
      const { data } = await axiosApi.put(`/portal/${identifier}/`, toRaw(values))
      portalFormItem.reset(data)
      alert.success(`${getPortalName(values)} updates were saved successfully!`)
    } catch (error) {
      alert.error(`${getPortalName(values)} updates couldn't be saved. Please try again.`)
      console.log(error)
    } finally {
      isLoading.value = false
      isSaving.value = false
    }
  }

  async function saveAndPublishPortal(portalFormItem) {
    const { hasChanges, values } = portalFormItem
    const { identifier } = values
    isLoading.value = true
    isPublishing.value = true
    try {
      if (hasChanges) {
        await axiosApi.put(`/portal/${identifier}/`, toRaw(values))
      }
      const { data } = await axiosApi.put(`/portal/${identifier}/publish/`)
      portalFormItem.reset(data)
      alert.success(`${getPortalName(data)} updates were published successfully!`)
    } catch (error) {
      alert.error(
        `Failed to publish updates for ${getPortalName(values)}. Please try again.`
      )
      console.log(error)
    } finally {
      isLoading.value = false
      isPublishing.value = false
    }
  }

  function buildPortalItem(initialValues) {
    return buildFormState({
      validationSchema,
      initialValues,
      fieldsMapping,
      editableFieldPaths: editablePortalFieldPaths.value
    })
  }

  function setSelectedPortalIndex(index) {
    selectedPortalIndex.value = index
  }

  function selectPortalByIdentifier(identifier) {
    const index = getPortalIndex(identifier)
    setSelectedPortalIndex(index > -1 ? index : null)
  }

  function getPortalIndex(identifier) {
    return portals.value.findIndex(({ values = {} }) => (
      values.identifier === identifier
    ))
  }

  async function saveDraftSelectedPortal() {
    if (selectedPortal.value && selectedPortal.value.validateOnlyEditable()) {
      await updatePortal(selectedPortal.value)
    }
  }

  async function publishSelectedPortal() {
    if (selectedPortal.value && selectedPortal.value.validate()) {
      await saveAndPublishPortal(selectedPortal.value)
    }
  }

  const canSaveDraft = computed(() => {
    if (!selectedPortal.value) { return false }

    const { hasChanges, editableFieldsValid } = selectedPortal.value

    return !isLoading.value &&
            !isSaving.value &&
            canSavePortalDraft.value
            && hasChanges
            && editableFieldsValid
  })

  const canPublish = computed(() => {
    if (!selectedPortal.value) { return false }
  
    const { values, valid } = selectedPortal.value
    const { publishable } = values

    return (
      !isLoading.value &&
      !isSaving.value &&
      !isPublishing.value &&
      canPublishPortal.value &&
      valid &&
      (publishable || canSaveDraft.value)
    )
  })

  return {
    isLoading,
    isSaving,
    isPublishing,
    portals,
    selectedPortalIndex,
    selectedPortal,
    canSaveDraft,
    canPublish,
    createPortal,
    fetchPortals,
    setSelectedPortalIndex,
    selectPortalByIdentifier,
    saveDraftSelectedPortal,
    publishSelectedPortal
  }
})
