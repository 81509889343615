<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import CustomTabs from '@/components/CustomTabs.vue'
import CustomTab from '@/components/CustomTab.vue'
import LogoAssetsForm from '@/components/customize/LogoAssetsForm.vue'
import FontsForm from '@/components/customize/FontsForm.vue'
import OrderLookupForm from '@/components/customize/OrderLookupForm.vue'
import HowItWorksForm from '@/components/customize/HowItWorksForm.vue'
import ButtonsStylesForm from '@/components/customize/ButtonsStylesForm.vue'
import SitewideForm from '@/components/customize/SitewideForm.vue'
import RefundForm from '@/components/customize/RefundForm.vue'
import ReturnSummaryForm from '@/components/customize/ReturnSummaryForm.vue'
import IneligibleReturnsModalForm from '@/components/customize/IneligibleReturnsModalForm.vue'
import { PORTAL_TAB_PATHS } from '@/constants.js'

const { selectedPortal } = storeToRefs(usePortalsStore())
</script>

<template>
  <CustomTabs>
    <CustomTab
      title="Global Styles"
      :showHeader="false"
      :draft="selectedPortal.hasChangesOn([
        ...PORTAL_TAB_PATHS.LOGO_ASSETS,
        ...PORTAL_TAB_PATHS.FONTS,
        ...PORTAL_TAB_PATHS.BUTTONS
      ])"
      :error="selectedPortal.hasErrorsOn([
        ...PORTAL_TAB_PATHS.LOGO_ASSETS,
        ...PORTAL_TAB_PATHS.FONTS,
        ...PORTAL_TAB_PATHS.BUTTONS
      ])"
    >
      <CustomTabs customClass="secondary">
        <CustomTab
          title="Logo Assets"
          :showHeader="false"
          :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.LOGO_ASSETS)"
          :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.LOGO_ASSETS)"
        >
          <LogoAssetsForm />
        </CustomTab>
        <CustomTab
          title="FONTS"
          :showHeader="false"
          :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.FONTS)"
          :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.FONTS)"
        >
          <FontsForm />
        </CustomTab>
        <CustomTab
          title="Buttons"
          :showHeader="false"
          :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.BUTTONS)"
          :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.BUTTONS)"
        >
          <ButtonsStylesForm />
        </CustomTab>
      </CustomTabs>
    </CustomTab>
    <CustomTab
      title="Content"
      :showHeader="false"
      :draft="selectedPortal.hasChangesOn([
        ...PORTAL_TAB_PATHS.SITEWIDE,
        ...PORTAL_TAB_PATHS.ORDER_LOOKUP,
        ...PORTAL_TAB_PATHS.ORDER_ID_HELP_MODAL,
        ...PORTAL_TAB_PATHS.HOW_IT_WORKS_MODAL,
        ...PORTAL_TAB_PATHS.INELIGIBLE_RETURNS_MODAL,
        ...PORTAL_TAB_PATHS.REFUND,
        ...PORTAL_TAB_PATHS.RETURN_SUMMARY
      ])"
      :error="selectedPortal.hasErrorsOn([
        ...PORTAL_TAB_PATHS.SITEWIDE,
        ...PORTAL_TAB_PATHS.ORDER_LOOKUP,
        ...PORTAL_TAB_PATHS.ORDER_ID_HELP_MODAL,
        ...PORTAL_TAB_PATHS.HOW_IT_WORKS_MODAL,
        ...PORTAL_TAB_PATHS.INELIGIBLE_RETURNS_MODAL,
        ...PORTAL_TAB_PATHS.REFUND,
        ...PORTAL_TAB_PATHS.RETURN_SUMMARY
      ])"
    >
      <CustomTabs customClass="secondary">
        <CustomTab
          title="SITEWIDE"
          :showHeader="false"
          :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.SITEWIDE)"
          :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.SITEWIDE)"
        >
          <SitewideForm />
        </CustomTab>
        <CustomTab
          title="ORDER LOOKUP"
          :showHeader="false"
          :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.ORDER_LOOKUP)"
          :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.ORDER_LOOKUP)"
        >
          <OrderLookupForm />
        </CustomTab>
<!-- TODO: [RX-6142] - Restore this modal -->
<!--        <CustomTab-->
<!--          title="ORDER ID HELP MODAL"-->
<!--          :showHeader="false"-->
<!--          :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.ORDER_ID_HELP_MODAL)"-->
<!--          :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.ORDER_ID_HELP_MODAL)"-->
<!--        >-->
<!--          <OrderIdHelpForm/>-->
<!--        </CustomTab>-->
        <CustomTab
          title="HOW IT WORKS MODAL"
          :showHeader="false"
          :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.HOW_IT_WORKS_MODAL)"
          :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.HOW_IT_WORKS_MODAL)"
        >
          <HowItWorksForm/>
        </CustomTab>
        <CustomTab
          title="INELIGIBLE RETURNS MODAL"
          :showHeader="false"
          :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.INELIGIBLE_RETURNS_MODAL)"
          :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.INELIGIBLE_RETURNS_MODAL)"
        >
          <IneligibleReturnsModalForm />
        </CustomTab>
        <CustomTab
          title="INSTANT STORE CREDIT"
          :showHeader="false"
          :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.REFUND)"
          :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.REFUND)"
        >
          <RefundForm />
        </CustomTab>
        <CustomTab
          title="RETURN SUMMARY"
          :showHeader="false"
          :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.RETURN_SUMMARY)"
          :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.RETURN_SUMMARY)"
        >
          <ReturnSummaryForm/>
        </CustomTab>
      </CustomTabs>
    </CustomTab>
  </CustomTabs>
</template>
