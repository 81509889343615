import { AUTH_TOKEN_STORAGE_KEY } from '@/constants'
import { defaultTo } from 'lodash'

const Auth = {
  setToken: (newToken) => {
    localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, newToken)
  },
  getToken: () => {
    return localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)
  },
  clearToken: () => {
    localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY)
  },
  checkToken: () => {
    const urlParams = new URLSearchParams(window.location.search)
    const newToken = urlParams.get('token')
    if (newToken && newToken.length > 0) {
      setToken(newToken)
    }
    return getToken()
  },
  redirectToLogin: () => {
    // REDIRECT TO OPTITURN LOGIN => with ?returnUrl param pointing back to us
    const loginUrl = defaultTo(import.meta.env.VITE_LOGIN_URL, 'https://optiturn.com/session/new')
    const returnUrl = encodeURIComponent(`${window.location.origin}${window.location.pathname}`)
    window.location.href = `${loginUrl}?returnUrl=${returnUrl}&service=returns_portal_management`
  },
  logout: () => {
    clearToken()
    redirectToLogin()
  }
}

export const {
  setToken,
  getToken,
  clearToken,
  checkToken,
  redirectToLogin,
  logout
} = Auth
