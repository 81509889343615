import { object, boolean, number, array } from 'yup'

export default object({
  instant: object({ enabled: boolean() }),
  instant_gift_card: object({
    enabled: boolean(),
    bonus_credit_window_days: number().integer().positive(),
    bonus_credit_amount_tiers: array()
  }),
  standard: object({ enabled: boolean() }),
})
