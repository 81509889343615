import { object, string } from 'yup'

import policies from '@/validation_schemas/portal/policies'
import strings from '@/validation_schemas/portal/strings'
import styles from '@/validation_schemas/portal/styles'
import config from '@/validation_schemas/portal/config'

export default object({
  identifier: string().label('Portal identifier').required(),
  settings: object({
    policies,
    strings,
    styles,
    config
  })
})
