import { object, string, array } from 'yup'
import { WEB_FONTS, HEX_COLOR_REGEX } from '@/constants'

const fileSchema = object({
  name: string().required(),
  filename: string().required()
})

const brandFontFamilySchema = string()
  .nullable()
  .when('brand_font_assets', ([assets], schema) => {
    return assets?.length > 0
      ? schema.oneOf((assets || []).map(({ name }) => name))
      : schema
  })

const webFontFamilySchema = string().oneOf(WEB_FONTS).nullable()

const colorSchema = string()
  .nullable()
  .transform(value => (!value || value.length === 0 ? null : value))
  .matches(HEX_COLOR_REGEX, 'The color is invalid')

export default object({
  brand_font_assets: array().of(fileSchema).label('Brand fonts'),
  main_header_brand_font_family: brandFontFamilySchema.label('Main header brand font'),
  main_header_web_font_family: webFontFamilySchema.label('Main header backup web font'),
  sub_header_brand_font_family: brandFontFamilySchema.label('Sub-header brand font'),
  sub_header_web_font_family: webFontFamilySchema.label('Sub-header backup web font'),
  header_color: colorSchema.label('Header color'),
  body_brand_font_family: brandFontFamilySchema.label('Body brand font'),
  body_web_font_family: webFontFamilySchema.label('Body backup web font'),
  body_color: colorSchema.label('Body color')
})
