import { ref, computed, toRaw } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { axiosApi } from '@/api'
import { useAlertStore } from '@/stores/alert'
import { useUserStore } from '@/stores/user'
import { buildFormState, validate } from '@/utils/form'
import validationSchema from '@/validation_schemas/client'
import fieldsMapping from '@/stores/clients/fields_mapping'
import { isEmpty } from 'lodash'

export const useClientsStore = defineStore('clients', () => {
  const alert = useAlertStore()
  const { editableClientFieldPaths, canSaveClient } = storeToRefs(useUserStore())

  const isLoading = ref(false)
  const isSaving = ref(false)
  const clients = ref([])
  const selectedClientIndex = ref(null)
  const selectedClient = computed(() => {
    const index = selectedClientIndex.value
    if (index > -1 && index < clients.value.length) {
      return clients.value[index]
    }
    return null
  })

  const canSave = computed(() => (
    !isLoading.value &&
    !isSaving.value &&
    selectedClient.value?.hasChanges &&
    selectedClient.value?.editableFieldsValid &&
    canSaveClient.value
  ))

  const selectedClientValid = computed(() => {
    if (selectedClient.value) {
      const errors = validate(selectedClient.value.values, validationSchema)
      return isEmpty(errors)
    }
    return false
  })

  async function fetchClients() {
    isLoading.value = true
    try {
      const { data } = await axiosApi.get('/client/')
      clients.value = data.map(buildClientItem)
    } catch (error) {
      alert.error('An error occurred while fetching the clients.')
      console.log(error)
    } finally {
      isLoading.value = false
    }
  }

  async function updateClient(clientFormItem) {
    const { values } = clientFormItem
    const { name, slug } = values
    isLoading.value = true
    isSaving.value = true
    let succeed = false
    try {
      const { data } = await axiosApi.put(`/client/${slug}/`, toRaw(values))
      clientFormItem.reset(data)
      alert.success(`${name} updates were saved successfully!`)
      succeed = true
    } catch (error) {
      alert.error(`${name} updates couldn't be saved. Please try again.`)
      console.log(error)
    } finally {
      isLoading.value = false
      isSaving.value = false
    }
    return succeed
  }

  function setSelectedClientIndex(index) {
    selectedClientIndex.value = index
  }

  function selectClientBySlug(slug) {
    const index = clients.value.findIndex(client => (client.values.slug === slug))
    setSelectedClientIndex(index)
  }

  function buildClientItem(initialValues) {
    return buildFormState({
      validationSchema,
      initialValues,
      fieldsMapping,
      editableFieldPaths: editableClientFieldPaths.value
    })
  }

  async function saveSelectedClient() {
    if (selectedClient.value && selectedClient.value.validateOnlyEditable()) {
      return await updateClient(selectedClient.value)
    }
    return false
  }

  return {
    isLoading,
    isSaving,
    clients,
    selectedClient,
    selectedClientValid,
    canSave,
    fetchClients,
    setSelectedClientIndex,
    selectClientBySlug,
    saveSelectedClient
  }
})
