import { object, string } from 'yup'
import { isEmpty } from 'lodash'

export default object({
  ORDER_LOOKUP: object({
    RETURN_AND_TRACK_ORDERS: string().label('Order Lookup Header').required(),
    ENTER_ORDER_ID: string().label('Order Lookup Input label').required(),
    ENTER_ORDER_ID_PLACEHOLDER: string().label('Order Lookup Input hint text').required(),
    NEXT: string().label('Order Lookup Button label').required()
  }),
  RETURN_REVIEW: object({
    SHIPPING_FEE: string().label('Return Summary Shipping Fee Label').required()
  }),
  EXCHANGE_REFUND: object({
    INSTANT_GIFT_CARD: string().label('Instant Gift Card Headline').required(),
    INSTANT_GIFT_CARD_MESSAGE: string().label('Instant Gift Card Subcopy').required(),
    INSTANT_GIFT_CARD_DISCLAIMER: string().label('Gift Card Disclaimer').notRequired(),
  }),
  ORDER_DISPLAY: object({
    ITEM_RETURN_POLICY_TITLE: string().label('Return Window Expiration Modal Header').required(),
    ITEM_RETURN_POLICY_DESC: string().label('Return Window Expiration Modal Copy').required(),
    FINAL_SALE_MODAL_HEADER: string().label('Final Sale Modal Header').required(),
    FINAL_SALE_MODAL_BODY: string().label('Final Sale Modal Copy').required(),
    THIRD_PARTY_MODAL_HEADER: string().label('Third Party Modal Header').required(),
    THIRD_PARTY_MODAL_BODY: string().label('Third Party Modal Copy').required()
  }),
  HOW_IT_WORKS: object({
    HOW_IT_WORKS: string().label('How it Works Modal Header').required(),
    HOW_IT_WORKS_BODY: string().label('How it Works Modal Copy').notRequired()
  })
}).label('Strings JSON').test('json_non_empty', '${label} cannot be empty', value => (!isEmpty(value))).required()
