<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import CustomTabs from '@/components/CustomTabs.vue'
import CustomTab from '@/components/CustomTab.vue'
import ExchangesForm from '@/components/settings/ExchangesForm.vue'
import ReturnsForm from '@/components/settings/ReturnsForm.vue'
import AccountInfoForm from "@/components/settings/AccountInfoForm.vue"
import ShippingForm from '@/components/settings/ShippingForm.vue'
import { PORTAL_TAB_PATHS } from '@/constants.js'

const { selectedPortal } = storeToRefs(usePortalsStore())
</script>

<template>
  <CustomTabs>
    <CustomTab
      title="Account Info"
      :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.ACCOUNT_INFO)"
      :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.ACCOUNT_INFO)"
    >
      <AccountInfoForm/>
    </CustomTab>
    <CustomTab
      title="Returns"
      :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.RETURNS)"
      :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.RETURNS)"
    >
      <ReturnsForm/>
    </CustomTab>
    <CustomTab
      title="Repurchases"
      :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.EXCHANGES)"
      :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.EXCHANGES)"
    >
      <ExchangesForm />
    </CustomTab>
    <CustomTab
      title="Shipping"
      :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.SHIPPING)"
      :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.SHIPPING)"
    >
      <ShippingForm />
    </CustomTab>
  </CustomTabs>
</template>
