<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldError from '@/components/FormFieldError.vue'
import FormFieldTextarea from '@/components/FormFieldTextarea.vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())
</script>

<template>
  <div class="col-3">
    <h3>Ineligible Returns Modal</h3>
    <p>Include an explanation for why a return item is ineligible (e.g. if the item is final sale or the item’s return window has expired). This explanation will appear when the customer clicks on the “Why can’t I return this?” link.</p>
    <fieldset class="mt-5">
      <h3>Return Window Expiration Modal</h3>
      <div class="mt-3">
        <label class="form-label">Header *</label>
        <FormFieldInput
          type="text"
          class="form-control"
          :fieldModel="selectedPortal.fields.returnWindowExpirationModalHeader"
          :disabled="isLoading"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.returnWindowExpirationModalHeader" />
      </div>
      <div class="mt-3">
        <label class="form-label">Modal Copy *</label>
        <FormFieldTextarea
          class="form-control"
          :disabled="isLoading"
          :fieldModel="selectedPortal.fields.returnWindowExpirationModalCopy"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.returnWindowExpirationModalCopy" />
      </div>
    </fieldset>
    <fieldset class="mt-5">
      <h3>Final Sale Modal</h3>
      <div class="mt-3">
        <label class="form-label">Header *</label>
        <FormFieldInput
          type="text"
          class="form-control"
          :fieldModel="selectedPortal.fields.finalSaleModalHeader"
          :disabled="isLoading"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.finalSaleModalHeader" />
      </div>
      <div class="mt-3">
        <label class="form-label">Modal Copy *</label>
        <FormFieldTextarea
          class="form-control"
          :disabled="isLoading"
          :fieldModel="selectedPortal.fields.finalSaleModalCopy"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.finalSaleModalCopy" />
      </div>
    </fieldset>
    <fieldset class="mt-5">
      <h3>Third Party Modal</h3>
      <div class="mt-3">
        <label class="form-label">Header *</label>
        <FormFieldInput
          type="text"
          class="form-control"
          :fieldModel="selectedPortal.fields.thirdPartyModalHeader"
          :disabled="isLoading"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.thirdPartyModalHeader" />
      </div>
      <div class="mt-3">
        <label class="form-label">Modal Copy *</label>
        <FormFieldTextarea
          class="form-control"
          :disabled="isLoading"
          :fieldModel="selectedPortal.fields.thirdPartyModalCopy"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.thirdPartyModalCopy" />
      </div>
    </fieldset>
  </div>
</template>
