import { number } from 'yup'

export const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/

export const numberSchema = number().typeError('${label} must be a number')

export const decimalSchema = numberSchema.test(
    'maxDigitsAfterDecimal',
    '${label} format is invalid',
    (number) => (!number || patternTwoDigisAfterComma.test(number))
)
