import { array, boolean, object, string } from 'yup'
import moment from 'moment'
import { toNumber } from 'lodash'
import { numberSchema } from '@/validation_schemas/common'

import exchanges from '@/validation_schemas/portal/policies/exchanges'
import feature_flags from '@/validation_schemas/portal/policies/feature_flags'
import packing_slip from '@/validation_schemas/portal/policies/packing_slip'
import retailer_info from '@/validation_schemas/portal/policies/retailer_info'
import return_methods from '@/validation_schemas/portal/policies/return_methods'
import return_reasons from '@/validation_schemas/portal/policies/return_reasons'
import ship_to_address from '@/validation_schemas/portal/policies/ship_to_address'

const isValidDate = (value) => (moment(value).isValid())
const dateToString = (value) => (isValidDate(value) ? moment(value).format('YYYY-MM-DD') : null)

export const holidayReturnWindowSchema = object({
  start_date: string()
    .label('Start date')
    .required()
    .test('start_valid', '${label} must be a valid date', value => (isValidDate(value)))
    .transform(dateToString),
  end_date: string()
    .label('End date')
    .required()
    .test('end_valid', '${label} must be a valid date', value => (isValidDate(value)))
    .transform(dateToString),
  return_window_days: numberSchema
    .label('Return window days')
    .integer()
    .positive()
    .required()
    .min(1)
})

export default object({
  drop_off_window_days: numberSchema
    .label('Drop off window days')
    .integer()
    .positive()
    .required()
    .min(1),
  exchanges,
  feature_flags,
  holiday_return_windows: array().of(holidayReturnWindowSchema),
  packing_slip,
  refund_timing_estimate: string()
  .label('Refund timing estimate')
  .required()
  .matches( /^(\d+|\d+-\d+)$/g, {
      excludeEmptyString: true,
      message: '${label} must be an integer or 2 integers separated by a dash. Example: "3-5"'
  })
  .test('range_valid', '${label} must be a valid range', value => {
      const range = value.split('-')
      if (range.length === 2) {
          const start = toNumber(range[0])
          const end = toNumber(range[1])
          return start <= end
      }
      return range.length === 1
  }),
  retailer_info,
  return_methods,
  return_reasons,
  return_window_enabled: boolean(),
  return_window_days: numberSchema
  .label('Return window days')
  .positive()
  .integer()
  .min(0)
  .when('return_window_enabled', {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable()
  }),
  ship_to_address,
  supported_sort_classifications: array()
})
