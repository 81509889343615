<script setup>
import { useAlertStore } from '@/stores/alert'
import AlertMessage from '@/components/AlertMessage.vue'

const alertStore = useAlertStore()
</script>

<template>
  <TransitionGroup name="list">
    <AlertMessage
      v-for="(alert, index) in alertStore.alerts"
      :key="index"
      :messages="[alert.message]"
      :type="alert.type"
      closeable
      @close="alertStore.removeAlertByIndex(index)"
    />
  </TransitionGroup>
</template>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>
