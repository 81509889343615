import { object, string } from 'yup'
import { UNITED_STATES } from '@/constants'

export default object({
  name: string().label('Name').required(),
  company: string().label('Company').required(),
  street1: string().label('Street1').required(),
  street2: string().label('Street2').notRequired(),
  city: string().label('City').required(),
  state: string()
    .label('State')
    .required()
    .oneOf(Object.keys(UNITED_STATES)),
  zip: string()
    .label('Zip Code')
    .matches(/^\d{5}$/, 'Enter a valid zip code')
    .required()
})
