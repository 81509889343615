import { object, boolean, string } from 'yup'
import { isEmpty, isNull } from 'lodash'

export default object({
  add_variants_to_titles: boolean(),
  extra_item_identifier: string().label('Extra field').nullable(),
  extra_item_identifier_title: string().label('Extra field title')
    .when('extra_item_identifier', {
        is: (value) => !isEmpty(value) && !isNull(value),
        then: (schema => schema.required()),
        otherwise: (schema => schema.nullable())
    })
})
