<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import CustomTabs from '@/components/CustomTabs.vue'
import CustomTab from '@/components/CustomTab.vue'
import JsonEditor from '@/components/JsonEditor.vue'
import FormFieldError from '@/components/FormFieldError.vue'
import { PORTAL_TAB_PATHS } from '@/constants.js'

const { selectedPortal } = storeToRefs(usePortalsStore())

function onPoliciesJsonError(errors = []) {
  if (errors.length > 0) {
    selectedPortal.value.fields.policiesJson.setError('Policies JSON is invalid')
  } else {
    selectedPortal.value.fields.policiesJson.validate()
  }
}

function onStringsJsonError(errors = []) {
  if (errors.length > 0) {
    selectedPortal.value.fields.stringsJson.setError('Strings JSON is invalid')
  } else {
    selectedPortal.value.fields.stringsJson.validate()
  }
}

function onStylesJsonError(errors = []) {
  if (errors.length > 0) {
    selectedPortal.value.fields.stylesJson.setError('Styles JSON is invalid')
  } else {
    selectedPortal.value.fields.stylesJson.validate()
  }
}

function onConfigJsonError(errors = []) {
  if (errors.length > 0) {
    selectedPortal.value.fields.configJson.setError('Config JSON is invalid')
  } else {
    selectedPortal.value.fields.configJson.validate()
  }
}
</script>

<template>
  <CustomTabs>
    <CustomTab
      title="Policies JSON"
      :showHeader="false"
      :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.POLICIES_JSON)"
      :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.POLICIES_JSON)"
    >
      <h6>Policies JSON *</h6>
      <FormFieldError class="mb-2" :fieldModel="selectedPortal.fields.policiesJson" />
      <JsonEditor
        v-if="selectedPortal.fields.policiesJson.editable"
        v-model="selectedPortal.fields.policiesJson.inputValue"
        @onerror="onPoliciesJsonError"
      />
      <div v-else>You do not have permissions to access this section</div>
    </CustomTab>
    <CustomTab
      title="Strings JSON"
      :showHeader="false"
      :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.STRINGS_JSON)"
      :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.STRINGS_JSON)"
    >
      <h6>Strings JSON *</h6>
      <FormFieldError class="mb-2" :fieldModel="selectedPortal.fields.stringsJson" />
      <JsonEditor
        v-if="selectedPortal.fields.stringsJson.editable"
        v-model="selectedPortal.fields.stringsJson.inputValue"
        @onerror="onStringsJsonError"
      />
      <div v-else>You do not have permissions to access this section</div>
    </CustomTab>
    <CustomTab
      title="Styles JSON"
      :showHeader="false"
      :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.STYLES_JSON)"
      :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.STYLES_JSON)"
    >
      <h6>Styles JSON *</h6>
      <FormFieldError class="mb-2" :fieldModel="selectedPortal.fields.stylesJson" />
      <JsonEditor
        v-if="selectedPortal.fields.stylesJson.editable"
        v-model="selectedPortal.fields.stylesJson.inputValue"
        @onerror="onStylesJsonError"
      />
      <div v-else>You do not have permissions to access this section</div>
    </CustomTab>
    <CustomTab
      title="Config JSON"
      :showHeader="false"
      :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.CONFIG_JSON)"
      :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.CONFIG_JSON)"
    >
      <h6>Config JSON *</h6>
      <FormFieldError class="mb-2" :fieldModel="selectedPortal.fields.configJson" />
      <JsonEditor
        v-if="selectedPortal.fields.configJson.editable"
        v-model="selectedPortal.fields.configJson.inputValue"
        @onerror="onConfigJsonError"
      />
      <div v-else>You do not have permissions to access this section</div>
    </CustomTab>
  </CustomTabs>
</template>
