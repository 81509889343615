<script setup>
import { ref, computed } from 'vue'
import { getName, getExtension, buildFileObject } from '@/utils/file'
import { uploadFile } from '@/api'
import { useAlertStore } from '@/stores/alert'
import { sortBy, findIndex } from 'lodash'

const props = defineProps({ 
  modelValue: {
    type: Array,
    default() {
      return []
    }
  },
  location: String,
  accept: {
    type: Array,
    default() {
      return ['*']
    } 
  },
  disabled: Boolean
})

const emit = defineEmits(['update:modelValue'])

const alert = useAlertStore()

const fileInput = ref(null)
const isUploading = ref(false)

const files = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const sortedFiles = computed(() => {
  return sortBy(files.value, file => file.filename)
})

function onClickAdd() {
  fileInput.value.click()
}

function getFileIndex(filename) {
  return findIndex(files.value, fileItem => fileItem.filename === filename)
}

function removeFile(filename) {
  files.value = files.value.toSpliced(getFileIndex(filename), 1)
}

function handleFileChange(event) {
    const file = event.target.files[0]
    const filename = `${props.location}/${file.name}`

    if (getFileIndex(filename) > -1) {
      fileInput.value.value = null
      return
    }

    handleUploadFile(file, filename)
}

async function handleUploadFile(file, filename) {
  isUploading.value = true
  try {
    const { data } = await uploadFile(file, filename)
    files.value = (files.value || []).concat([buildFileObject(data)])
  } catch (error) {
    alert.error('An error occurred while uploading the file.')
  } finally {
    fileInput.value.value = null
    isUploading.value = false
  }
}
</script>

<template>
  <div class="file-list-uploader">
    <table v-if="sortedFiles?.length" class="table w-100">
      <thead>
        <tr>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(file, index) in sortedFiles" :key="index">
          <td class="align-middle">
            {{ `${getName(file.filename)}.${getExtension(file.filename)}` }}
          </td>
          <td class="text-end">
            <button
              type="button"
              class="btn btn-outline-primary"
              :disabled="disabled"
              @click="removeFile(file.filename)"
            >
              REMOVE
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <input
      type="file"
      ref="fileInput"
      @change="handleFileChange"
      :accept="accept.join(',')"
    />
    <button
      type="button"
      class="btn btn-primary px-5"
      :disabled="disabled || isUploading"
      @click="onClickAdd"
    >
      <span v-if="isUploading" class="spinner-border spinner-border-sm me-1"></span>
      ADD
    </button>
  </div>
</template>

<style lang="scss" scoped>
input[type="file"] {
    display: none;
}
</style>
