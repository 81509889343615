<script setup>
import { computed } from 'vue'
import FileUploader from '@/components/FileUploader.vue'
import { getFileUrl } from '@/utils/file'

const props = defineProps({ 
  modelValue: Object,
  name: String,
  location: String,
  maxSize: {
    type: Number,
    default() {
      return 0
    }
  },
  accept: {
    type: Array,
    default() {
      return ['image/png']
    } 
  },
  disabled: Boolean
})

const emit = defineEmits(['update:modelValue'])

const image = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
</script>

<template>
  <div class="image-uploader">
    <img
      v-if="image"
      :src="getFileUrl(image)"
      class="img-thumbnail mb-2 w-100"
      alt=""
    >
    <FileUploader
      v-model="image"
      :name="name"
      :location="location"
      :accept="accept"
      :disabled="disabled"
      :maxSize="maxSize"
      timestamp
    />  
  </div>
  
</template>

<style lang="scss" scoped>
.image-uploader {
  width: 150px;
}
</style>
