import { ref, computed, toRaw } from 'vue'
import {
  isEqual,
  isEmpty,
  isNil,
  isString,
  isObjectLike,
  isPlainObject,
  isBoolean,
  mapValues,
  get,
  set,
  cloneDeep,
  pick,
  values as getValues
} from 'lodash'

export function buildFormState({
  validationSchema,
  initialValues = {},
  fieldsMapping = {},
  editableFieldPaths = ['*']
}) {
  const fieldPaths = getValues(fieldsMapping)
  const editablePaths = getEditablePaths(fieldPaths)

  const initialValuesRef = ref(cloneDeep(initialValues))
  const values = ref(cloneDeep(initialValues))
  const errors = ref({})
  const hasChanges = ref(false)
  const valid = computed(() => (isEmpty(errors.value)))
  const fields = mapValues(fieldsMapping, (fieldPath) => {
    return {
      inputValue: computed({
        get() { return get(values.value, fieldPath, null) },
        set(newValue) {
          if (isFieldEditable(fieldPath)) {
            setFieldValue(fieldPath, newValue)
          }
        }
      }),
      error: computed(() => (errors.value[fieldPath])),
      editable: ref(isFieldEditable(fieldPath)),
      validate: () => { validateField(fieldPath) },
      setError: (error) => { setFieldError(fieldPath, error) },
      clearError: () => { clearFieldError(fieldPath) },
    }
  })
  const editablePathsErrors = computed(() => (pick(errors.value, editablePaths)))
  const editableFieldsValid = computed(() => (isEmpty(editablePathsErrors.value)))

  function getEditablePaths(paths) {
    if (editableFieldPaths.includes('*')) { return paths }

    return paths.filter(fieldPath => (
      editableFieldPaths.some(editableFieldPath => fieldPath.startsWith(editableFieldPath))
    ))
  }

  function isFieldEditable(fieldPath) {
    return editablePaths.includes(fieldPath)
  }

  function setFieldValue(fieldPath, newValue) {
    set(values.value, fieldPath, newValue)
    checkHasChanges()
    validateField(fieldPath)
  }

  function checkHasChanges() {
    const initial = removeEmptyKeysDeep(initialValuesRef.value)
    const current = removeEmptyKeysDeep(toRaw(values.value))
    hasChanges.value = !isEqual(initial, current)
  }

  function validateField(fieldPath) {
    const error = validateAt(fieldPath, values.value, validationSchema)
    if (error) {
      errors.value = { ...errors.value, [fieldPath]: error }
    } else {
      errors.value = Object.keys(errors.value).reduce((newErrors, key) => {
        const fieldPathArrayRegex = new RegExp(`^${fieldPath.replaceAll('.', '\\.')}\\[\\d+\\]`)
        if (key !== fieldPath && !fieldPathArrayRegex.test(key)) {
          newErrors[key] = errors.value[key]
        }
        return newErrors
      }, {})
    }
    return !error
  }

  function validateAll() {
    errors.value = validate(values.value, validationSchema)
    return valid.value
  }

  function validateOnlyEditable() {
    errors.value = {}
    editablePaths.forEach(fieldPath => (validateField(fieldPath)))
    return valid.value
  }

  function hasChangesOn(paths = []) {
    return paths.some(path => {
      const initialValue = get(removeEmptyKeysDeep(initialValuesRef.value), path)
      const currentValue = get(removeEmptyKeysDeep(toRaw(values.value)), path)
      return !isEqual(initialValue, currentValue)
    })
  }

  function hasErrorsOn(paths = []) {
    return paths.some(path => {
      return Object.keys(errors.value).some(pathWithErrors => {
        return pathWithErrors.startsWith(path)
      })
    })
  }

  function clearFieldError(fieldPath) {
    // eslint-disable-next-line no-unused-vars
    const { [fieldPath]: _error, ...other } = errors.value
    errors.value = other
  }

  function setFieldError(fieldPath, error) {
    errors.value[fieldPath] = error
  }

  function reset(newValues = initialValues) {
    hasChanges.value = false
    initialValuesRef.value = cloneDeep(newValues)
    values.value = cloneDeep(newValues)
    errors.value = {}
  }

  return {
    values,
    errors,
    hasChanges,
    valid,
    editableFieldsValid,
    fields,
    validate: validateAll,
    validateOnlyEditable,
    hasChangesOn,
    hasErrorsOn,
    reset
  }
}

export function validate(values, validationSchema) {
  const errors = {}
  try {
    validationSchema.validateSync(values, { abortEarly: false })
  } catch (error) {
    error.inner.forEach(({ path, message }) => {
      errors[path] = message
    })
  }
  return errors
}

function validateAt(fieldPath, values, validationSchema) {
  try {
    validationSchema.validateSyncAt(fieldPath, values)
  } catch ({ message }) {
    return message
  }
  return undefined
}

function removeEmptyKeysDeep(object) {
  if (!isPlainObject(object) || isEmptyValue(object)) { return object }

  return Object.keys(object).reduce((newObject, key) => {
    const value = removeEmptyKeysDeep(object[key])

    if (!isEmptyValue(value)) {
      newObject[key] = value
    }

    return newObject
  }, {})
}

function isEmptyValue(value) {
  return isNil(value) ||
    (isObjectLike(value) && isEmpty(value)) ||
      (isString(value) && value.trim().length === 0) ||
        (isBoolean(value) && value === false)
}
