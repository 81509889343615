import { boolean, object, array, string } from 'yup'
import {
  numberSchema,
  decimalSchema,
  patternTwoDigisAfterComma
} from '@/validation_schemas/common'
import {
  FEDEX_SERVICE_LEVELS,
  MAIL_CARRIERS,
  UPS_SERVICE_LEVELS,
} from '@/constants'

export const mailbackShippinAccountsSchema = object({
  mail_carrier_name: string().label('Carrier').required().oneOf(Object.values(MAIL_CARRIERS)),
  mail_carrier_title: string().label('Customer-Facing Carrier Name').required(),
  service_level: string().label('Service Carrier').required().oneOf(Object.values(UPS_SERVICE_LEVELS).concat(Object.values(FEDEX_SERVICE_LEVELS))),
  carrier_id: string().label('Carrier ID').required(),
  warehouse_id: string().label('Warehouse ID').notRequired(),
  returns_service_enabled: boolean().default(false),
  us_territory_fallback_enabled: boolean().default(false)
})

export default object({
  drop_offs_is_enabled: boolean(),
  drop_offs_flat_fee_amount: decimalSchema
    .label('Fee')
    .when('drop_offs_is_enabled', {
        is: true,
        then: schema => schema.required(),
        otherwise: schema => schema.nullable()
    }),
  drop_offs_distance_threshold: numberSchema
    .label('Minimum distance threshold')
    .positive()
    .integer()
    .when('drop_offs_is_enabled', {
        is: true,
        then: schema => schema.required(),
        otherwise: schema => schema.nullable()
    })
    .transform((value) => Number.isNaN(value) ? null : value),
  home_pickup_is_enabled: boolean(),
  home_pickup_flat_fee_amount: decimalSchema
    .label('Fee')
    .when('home_pickup_is_enabled', {
        is: true,
        then: schema => schema.required(),
        otherwise: schema => schema.nullable()
    }),
  mail_back_flat_fee_amount: decimalSchema
    .label('Fee')
    .required(),
  mail_back_has_only_method_offered_fee_enabled: boolean(),
  mail_back_only_method_offered_fee: numberSchema.label('Alternative fee')
    .when('mail_back_has_only_method_offered_fee_enabled', {
        is: true,
        then: (schema) => schema.required().test(
            "maxDigitsAfterDecimal",
            "${label} must be a valid amount",
            (number) => patternTwoDigisAfterComma.test(number)
        ),
        otherwise: (schema) => schema.nullable()
    }),
  mailback_shipping_accounts: array().of(mailbackShippinAccountsSchema)
})
