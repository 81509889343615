import { createRouter, createWebHistory } from 'vue-router'

import IndexView from '@/views/IndexView.vue'
import PortalListView from '@/views/PortalListView.vue'
import PortalDetailView from '@/views/PortalDetailView.vue'
import CustomizeView from '@/views/CustomizeView.vue'
import PoliciesView from '@/views/PoliciesView.vue'
import SettingsView from '@/views/SettingsView.vue'
import DevelopersView from '@/views/DevelopersView.vue'
import ClientSettingsView from '@/views/ClientSettingsView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: IndexView
    },
    {
      path: '/clients/:clientSlug',
      name: 'clientDetail',
      redirect: { name: 'portalList' },
      children: [
        {
          path: 'portals',
          name: 'portalList',
          component: PortalListView
        },
        {
          path: 'settings',
          name: 'clientSettings',
          component: ClientSettingsView
        }
      ]
    },
    {
      path: '/clients/:clientSlug/portals/:portalIdentifier',
      name: 'portalDetail',
      redirect: { name: 'policies' },
      component: PortalDetailView,
      children: [
        {
          path: 'customize',
          name: 'customize',
          component: CustomizeView,
          meta: { title: 'Customize Portal Appearance' }
        },
        {
          path: 'policies',
          name: 'policies',
          component: PoliciesView,
          meta: { title: 'Policies' }
        },
        {
          path: 'settings',
          name: 'settings',
          component: SettingsView,
          meta: { title: 'Settings' }
        },
        {
          path: 'developers',
          name: 'developers',
          component: DevelopersView,
          meta: { title: 'Developers' }
        }
      ]
    }
  ]
})

export default router
