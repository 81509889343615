<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import { capitalize } from 'lodash'
import ButtonPreview from '@/components/customize/ButtonPreview.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldError from '@/components/FormFieldError.vue'

const props = defineProps({
  type: {
    type: String,
    default: 'primary'
  },
  state: {
    type: String,
    default: 'active'
  }
})

const { selectedPortal, isLoading } = storeToRefs(usePortalsStore())

const backgroundColorFormField = computed(() => (
  selectedPortal.value.fields[`button${capitalize(props.type)}${capitalize(props.state)}BackgroundColor`]
))

const borderColorFormField = computed(() => (
  selectedPortal.value.fields[`button${capitalize(props.type)}${capitalize(props.state)}BorderColor`]
))

const textColorFormField = computed(() => (
  selectedPortal.value.fields[`button${capitalize(props.type)}${capitalize(props.state)}TextColor`]
))
</script>

<template>
  <div>
    <div class="d-flex justify-content-center align-items-center flex-column">
      <label class="mb-2">{{ capitalize(state) }}</label>
      <ButtonPreview :type="type" :state="state" />
    </div>
    <div
      v-if="backgroundColorFormField"
      class="mt-3"
    >
      <label class="mb-2">Background *</label>
      <FormFieldInput
        type="text"
        class="form-control"
        :fieldModel="backgroundColorFormField"
        :disabled="isLoading"
      />
      <FormFieldError :fieldModel="backgroundColorFormField" />
    </div>
    <div
      v-if="borderColorFormField"
      class="mt-3"
    >
      <label class="mb-2">Border *</label>
      <FormFieldInput
        type="text"
        class="form-control"
        :fieldModel="borderColorFormField"
        :disabled="isLoading"
      />
      <FormFieldError :fieldModel="borderColorFormField" />
    </div>
    <div
      v-if="textColorFormField"
      class="mt-3"
    >
      <label class="mb-2">Text *</label>
      <FormFieldInput
        type="text"
        class="form-control"
        :fieldModel="textColorFormField"
        :disabled="isLoading"
      />
      <FormFieldError :fieldModel="textColorFormField" />
    </div>
  </div>
</template>
