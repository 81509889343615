import { object } from 'yup'

import buttons from '@/validation_schemas/portal/styles/buttons'
import fonts from '@/validation_schemas/portal/styles/fonts'
import logo_assets from '@/validation_schemas/portal/styles/logo_assets'
import order_id_help_modal from '@/validation_schemas/portal/styles/order_id_help_modal'
import site_wide_info from '@/validation_schemas/portal/styles/site_wide_info'

export default object({
  buttons,
  fonts,
  logo_assets,
  order_id_help_modal,
  order_lookup_image: object().label('Order lookup image').notRequired(),
  site_wide_info
})
