<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import HolidayReturnWindowsTable from '@/components/policies/HolidayReturnWindowsTable.vue'
import InfoTooltip from '@/components/InfoTooltip.vue'
import FormFieldRadio from '@/components/FormFieldRadio.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldNumber from '@/components/FormFieldNumber.vue'
import FormFieldError from '@/components/FormFieldError.vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())

function onRemoveHolidayReturnWindow(index) {
  const { fields } = selectedPortal.value
  fields.holidayReturnWindows.inputValue = (fields.holidayReturnWindows.inputValue || []).toSpliced(index, 1)
}

function onAddHolidayReturnWindow(item) {
  const { fields } = selectedPortal.value
  fields.holidayReturnWindows.inputValue = (fields.holidayReturnWindows.inputValue || []).concat([item])
}
</script>

<template>
  <fieldset>
    <h6>Returns</h6>
    <label class="form-label">Do you have a return window? *</label>
    <div class="form-check">
      <FormFieldRadio
        class="form-check-input"
        :fieldModel="selectedPortal.fields.returnWindowEnabled"
        :disabled="isLoading"
        :value="true"
        @change="selectedPortal.fields.returnWindowDays.inputValue = null"
      />
      <label class="form-check-label">Yes</label>
    </div>
    <div class="form-check">
      <FormFieldRadio
        class="form-check-input"
        :fieldModel="selectedPortal.fields.returnWindowEnabled"
        :disabled="isLoading"
        :value="false"
        @change="selectedPortal.fields.returnWindowDays.inputValue = null"
      />
      <label class="form-check-label">
        No, customers can return items at any time
      </label>
    </div>
    <br>
    <label class="form-label">
      How many days do customers have to return an item?
      <span v-if="selectedPortal.fields.returnWindowEnabled.inputValue">*</span>
    </label>
    <div class="d-flex align-items-center">
      <FormFieldNumber
        class="form-control small-input-number"
        :placeholder="selectedPortal.fields.returnWindowEnabled.inputValue ? '' : 'N/A'"
        :fieldModel="selectedPortal.fields.returnWindowDays"
        :disabled="isLoading || !selectedPortal.fields.returnWindowEnabled.inputValue"
      />
      &nbsp;days from the ship date of their order
    </div>
    <FormFieldError :fieldModel="selectedPortal.fields.returnWindowDays" />
  </fieldset>
  <br>
  <fieldset>
    <h6 class="d-flex align-items-center">
      Refunds
      &nbsp;
      <InfoTooltip
        title="A refund time window is the length of time it takes for your customers to receive their refund. Retailers issue a refund upon receipt of the return at a warehouse, store, Express Returns drop-off location or home pick-up."
        placement="top"
      />
    </h6>
    <label class="form-label">What is your refund time window? *</label>
    <div class="d-flex align-items-center">
      Within&nbsp;
      <FormFieldInput
        type="text"
        class="refund-timing-estimate form-control"
        :fieldModel="selectedPortal.fields.refundTimingEstimate"
        :disabled="isLoading"
      />
      &nbsp;days of receipt
    </div>
    <FormFieldError :fieldModel="selectedPortal.fields.refundTimingEstimate" />
  </fieldset>
  <br>
  <fieldset>
    <h6 class="d-flex align-items-center">
      Drop-offs
      &nbsp;
      <InfoTooltip
        title="A drop-off window is a recommended time frame for your customers to drop off their returns at a designation drop-off location. A drop-off window encourages customers to complete the return as soon as possible."
        placement="top"
      />
    </h6>
    <label class="form-label">
      When would you like customers to drop off their returns? *
    </label>
    <div class="d-flex align-items-center">
      Within&nbsp;
      <FormFieldNumber
        class="form-control small-input-number"
        :fieldModel="selectedPortal.fields.dropOffWindowDays"
        :disabled="isLoading"
      />
      &nbsp;days of their return initiation
    </div>
    <FormFieldError :fieldModel="selectedPortal.fields.dropOffWindowDays" />
  </fieldset>
  <br>
  <fieldset>
    <h6>Holidays</h6>
    <label class="form-label">When are your holiday return windows? (Optional)</label>
    <HolidayReturnWindowsTable
      :items="selectedPortal.fields.holidayReturnWindows.inputValue"
      :disabled="isLoading || !selectedPortal.fields.holidayReturnWindows.editable"
      @add="onAddHolidayReturnWindow"
      @remove="onRemoveHolidayReturnWindow"
    />
  </fieldset>
</template>

<style lang="scss" scoped>
.refund-timing-estimate {
  width: 62px;
  text-align: center;
}
</style>
