import { object, string, boolean } from 'yup'

export default object({
  enabled: boolean(),
  help_question: string()
    .label('Order ID help link')
    // .when('enabled', {
    //     is: true,
    //     then: schema => schema.required(),
    //     otherwise: schema => schema.nullable()
    // })
    .nullable(),
  header: string()
    .label('Header')
    // .when('enabled', {
    //     is: true,
    //     then: schema => schema.required(),
    //     otherwise: schema => schema.nullable()
    // })
    .nullable(),
  block_one_msg: string()
    .label('Block 1 message')
    // .when('enabled', {
    //     is: true,
    //     then: schema => schema.required(),
    //     otherwise: schema => schema.nullable()
    // })
    .nullable(),
  block_one_img: object().nullable(),
  block_two_msg: string().nullable(),
  block_two_img: object().nullable(),
  block_three_msg: string().nullable(),
  block_three_img: object().nullable()
})
