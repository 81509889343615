<script setup>
import { ref, toRaw } from 'vue'
import { buildFormState } from '@/utils/form'
import { holidayReturnWindowSchema } from '@/validation_schemas/portal/policies.js'
import moment from 'moment'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldNumber from '@/components/FormFieldNumber.vue'
import FormFieldError from '@/components/FormFieldError.vue'

defineProps({
  items: Array,
  disabled: Boolean
})

const emit = defineEmits(['add', 'remove'])

function formatDate(date) {
  return moment(date).format('MM/DD/YYYY')
}

const addForm = ref(buildFormState({
  validationSchema: holidayReturnWindowSchema,
  initialValues: {},
  fieldsMapping: {
    startDate: 'start_date',
    endDate: 'end_date',
    returnWindowDays: 'return_window_days'
  }
}))

function onClickAdd() {
  const { values, validate, reset } = addForm.value
  if (validate()) {
    emit('add', toRaw(values))
    reset()
  }
}

function onClickRemove(index) {
   emit('remove', index)
}
</script>

<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>DATE RANGE</th>
        <th>RETURN WINDOW</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>{{ formatDate(item.start_date) }} to {{ formatDate(item.end_date) }}</td>
        <td>Customers have up to {{ item.return_window_days }} days from the ship date of their order to return items.</td>
        <td class="text-end">
          <button
            type="button"
            class="btn btn-primary-outline border-0"
            @click="onClickRemove(index)"
            :disabled="disabled"
          >
            <BootstrapIcon name="bs-icon-x-lg" />
          </button>
        </td>
      </tr>
    </tbody>
    <tfoot>
        <tr>
        <td>
          <div class="d-flex align-items-center">
            <div>
              <FormFieldInput
                type="date"
                class="form-control"
                :fieldModel="addForm.fields.startDate"
                :max="addForm.fields.endDate.inputValue"
                :disabled="disabled"
              />
              <FormFieldError :fieldModel="addForm.fields.startDate" />
            </div>
            &nbsp;to&nbsp;
            <div>
              <FormFieldInput
                type="date"
                class="form-control"
                :fieldModel="addForm.fields.endDate"
                :min="addForm.fields.startDate.inputValue"
                :disabled="disabled"
              />
              <FormFieldError :fieldModel="addForm.fields.endDate" />
            </div>
          </div>
        </td>
        <td>
          <div class="d-flex align-items-center">
            Customers have up to&nbsp;
            <FormFieldNumber
              class="form-control small-input-number"
              :fieldModel="addForm.fields.returnWindowDays"
              :disabled="disabled"
            />
            &nbsp;days from the ship date of their order to return items.
          </div>
          <FormFieldError :fieldModel="addForm.fields.returnWindowDays" />
        </td>
        <td class="text-end">
          <button
            type="button"
            class="btn btn-outline-primary"
            :disabled="disabled || !addForm.valid"
            @click="onClickAdd"
          >
            ADD
          </button>
        </td>
      </tr>
    </tfoot>
  </table>
</template>
