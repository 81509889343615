<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useClientsStore } from '@/stores/clients'
import { usePortalsStore } from '@/stores/portals'
import DraftIcon from "@/components/DraftIcon.vue"
import ErrorIcon from "@/components/ErrorIcon.vue"

const clientsStore = useClientsStore()
const portalsStore = usePortalsStore()

const { selectedClient } = storeToRefs(clientsStore)
const { selectedPortal } = storeToRefs(portalsStore)

const props = defineProps({
  text: String,
  name: String,
  draft: Boolean,
  error: Boolean
})

const to = computed(() => {
  if (selectedClient.value && selectedPortal.value) {
    const { slug } = selectedClient.value.values
    const { identifier } = selectedPortal.value.values
    return {
      name: props.name,
      params: {
        clientSlug: slug,
        portalIdentifier: identifier
      }
    }
  }
  return {}
})
</script>

<template>
  <router-link
    :to="to"
    class="nav-link link-dark d-flex justify-content-between align-items-center portal-nav-link"
    active-class="active"
  >
    <slot></slot>
    <div>
      <DraftIcon v-if="!error && draft" />
      <ErrorIcon v-if="error" />
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
.portal-nav-link {
  border-right-width: 4px;
  border-right-style: solid;
  border-color: transparent;

  &.active {
    font-weight: 700;
    border-right-color: #FDC014;
  }
}
</style>
