import { array, boolean, object, string } from 'yup'
import { EXCHANGE_OFFERS } from '@/constants'

export const returnReasonItemSchema = object({
  name: string().label('Customer-facing reason').required(),
  waive_shipping_fee_enabled: boolean().default(false),
  exchange_offer: string().label('Exchange offer').required().oneOf(Object.values(EXCHANGE_OFFERS))
})

export const returnReasonCategorySchema = object({
  category_name: string().label('Reason Category Name').required(),
  default_category_enabled: boolean(),
  items: array().of(returnReasonItemSchema)
})

export default array().of(returnReasonCategorySchema)
