<script setup>
import { onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useClientsStore } from '@/stores/clients'
import { usePortalsStore } from '@/stores/portals'
import AppHeader from '@/components/AppHeader.vue'
import AppAlertMessage from '@/components/AppAlertMessage.vue'
import PortalStatusAlertMessage from '@/components/PortalStatusAlertMessage.vue'

const route = useRoute()
const userStore = useUserStore()
const clientsStore = useClientsStore()
const portalsStore = usePortalsStore()

const { clients, selectedClient } = storeToRefs(clientsStore)
const { portals } = storeToRefs(portalsStore)

onMounted(async () => {
  await userStore.fetchUser()
  await clientsStore.fetchClients()

  trySelectClient(route.params.clientSlug)
})

watch(selectedClient, async (client) => {
  if (client) {
    await portalsStore.fetchPortals(client.values.id)

    trySelectPortal(route.params.portalIdentifier)
  }
})

watch(() => route.params.clientSlug, trySelectClient)
watch(() => route.params.portalIdentifier, trySelectPortal)

function trySelectClient(slug) {
  if (clients.value.length) {
    if (slug) {
      clientsStore.selectClientBySlug(slug)
    } else {
      clientsStore.setSelectedClientIndex(0)
    }
  }
}

function trySelectPortal(identifier) {
  if (portals.value.length && identifier) {
    portalsStore.selectPortalByIdentifier(identifier)
  }
}
</script>

<template>
  <div class="d-flex flex-column vh-100">
    <AppHeader />
    <AppAlertMessage />
    <PortalStatusAlertMessage />
    <main class="container-fluid p-0 d-flex flex-fill flex-column">
      <RouterView />
    </main>
  </div>
</template>
