import { last } from 'lodash'

export function getName(filename) {
  return filename.replace(/^.*[\\/]/, '').replace(/\.[^/.]+$/, '')
}

export function getExtension(filename) {
  return last(filename.split('.'))
}

export function buildFileObject({ filename }) {
  return {
    name: getName(filename),
    filename
  }
}

export function getFileUrl({ filename }) {
  const baseUrl = import.meta.env.VITE_STORAGE_URL
  return baseUrl.endsWith('/') ? `${baseUrl}${filename}` : `${baseUrl}/${filename}`
}
