<script setup>
import logo from '@/assets/images/OT_icon.svg'
import arrow_back from '@/assets/images/arrow_back.svg'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useClientsStore } from '@/stores/clients'
import { clearToken } from '@/utils/auth'

const otUrl = import.meta.env.VITE_OPTITURN_URL
const router = useRouter()

const { isLoading, user } = storeToRefs(useUserStore())
const { clients, selectedClient } = storeToRefs(useClientsStore())

function onSelectClient(client) {
  router.push({ name: 'clientDetail', params: { clientSlug: client.values.slug } })
}
</script>
<template>
  <nav class="navbar bg-dark">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link link-light ps-0 d-flex align-items-center" :href="otUrl" @click="clearToken">
            <img alt="" :src="arrow_back">&nbsp;<b>Back to Optiturn</b>&nbsp;<img alt="" :src="logo" class="ms-1">
          </a>
        </li>
      </ul>
      <ul class="nav justify-content-end">
        <li v-if="clients.length > 1" class="nav-item">
          <div class="d-flex align-items-center px-3">
            <div class="text-light me-2">Client:</div>
            <div class="dropdown">
              <button
                class="btn
                      bg-white
                      d-flex
                      align-items-center
                      justify-content-between"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                :disabled="isLoading"
              >
                <b>{{ selectedClient?.values?.name }}</b>
                <BootstrapIcon name="bs-icon-chevron-expand" />
              </button>
              <ul class="dropdown-menu">
                <li v-for="(client, index) in clients" :key="index">
                  <a
                    class="dropdown-item d-flex justify-content-between align-items-center"
                    href="#"
                    @click="onSelectClient(client)"
                  >
                    {{ client.values.name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-item"><div class="nav-link link-light">{{ user?.username }}</div></li>
      </ul>
    </div>
  </nav>
</template>
