import './assets/main.scss'

import 'bootstrap'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

const app = createApp(App)
const pinia = createPinia()

app.use(router)
app.use(pinia)

import BootstrapIcon from '~virtual/svg-component'
app.component('BootstrapIcon', BootstrapIcon)

app.mount('#app')
