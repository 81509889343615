<script setup>
import { ref, computed, toRaw, watch, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import { buildFormState } from '@/utils/form'
import { returnReasonCategorySchema } from '@/validation_schemas/portal/policies/return_reasons'
// hide the button temporally https://optoro.atlassian.net/browse/RX-6246
// import { EXCHANGE_OFFERS } from '@/constants'
import ReturnReasonsTable from '@/components/policies/ReturnReasonsTable.vue'
import DraftIcon from '@/components/DraftIcon.vue'
import ErrorIcon from '@/components/ErrorIcon.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldError from '@/components/FormFieldError.vue'

defineProps({ disabled: Boolean })

const { selectedPortal } = storeToRefs(usePortalsStore())

const categories = ref([])
const selectedCategoryIndex = ref(0)
const selectedCategory = computed(() => {
  return (selectedCategoryIndex.value > -1 && selectedCategoryIndex.value < categories.value.length)
    ? categories.value[selectedCategoryIndex.value]
    : null
})
const rawCategories = computed(() => (
  categories.value.map(({ values }) => toRaw(values))
))

onMounted(() => { loadData(selectedPortal.value) })

watch(selectedPortal, loadData)

watch(categories, () => {
  selectedPortal.value.fields.returnReasons.inputValue = rawCategories.value
}, { deep: true })

function loadData(portal) {
  if (portal) {
    categories.value = (portal.fields.returnReasons.inputValue || []).map(category => {
      return buildCategoryFormItem(toRaw(category))
    })
  }
}
// hide the button temporally https://optoro.atlassian.net/browse/RX-6246
// function addCategory() {
//   const default_category_enabled = categories.value.length === 0
//   const newCategory = {
//     category_name: default_category_enabled ? 'Default' : 'New Category',
//     default_category_enabled,
//     items: [
//       {
//         name: 'Other',
//         exchange_offer: EXCHANGE_OFFERS.OTHER
//       }
//     ]
//   }
//   categories.value = categories.value.concat(buildCategoryFormItem(newCategory))
//   selectedCategoryIndex.value = categories.value.length - 1
// }

function removeCategory(index) {
  categories.value = categories.value.toSpliced(index, 1)
  selectedCategoryIndex.value = index < categories.value.length ? index : (index - 1)
}

function buildCategoryFormItem(initialValues) {
  const item = buildFormState({
    validationSchema: returnReasonCategorySchema,
    initialValues,
    fieldsMapping: {
      categoryName: 'category_name',
      defaultCategoryEnabled: 'default_category_enabled',
      items: 'items'
    }
  })
  item.validate()
  return item
}

function onAddReturnReason(item) {
  const { fields } = selectedCategory.value
  fields.items.inputValue = [item].concat(fields.items.inputValue || [])
}

function onUpdateReturnReason(index, values) {
  const { fields } = selectedCategory.value
  const items = toRaw(fields.items.inputValue)
  fields.items.inputValue = [
    ...items.slice(0, index),
    values,
    ...items.slice((index + 1), items.length)
  ]
}

function onRemoveReturnReason(index) {
  const { fields } = selectedCategory.value
  fields.items.inputValue = (fields.items.inputValue || []).toSpliced(index, 1)
}
</script>

<template>
  <p class="mb-5">
    Add reasons for your shoppers to select when they return an item through the Portal.
  </p>
  <div class="d-flex flex-fill">
    <div
      class="nav-container
            border-end
            border-dark-subtle
            pe-4"
    >
      <ul class="nav d-flex flex-column">
        <li
          v-for="(category, index) in categories"
          :key="index"
          class="nav-item
                  p-3
                  border-bottom
                  d-flex
                  justify-content-between
                  align-items-center"
          :class="{ 'fw-bold': selectedCategoryIndex === index }"
          @click="selectedCategoryIndex = index"
        >
          <div class="d-flex justify-content-center">
            {{ category.values.category_name }}
            <DraftIcon class="ms-2" v-if="category.valid && category.hasChanges" />
            <ErrorIcon class="ms-2" v-if="!category.valid"/>
          </div>
          <BootstrapIcon name="bs-icon-chevron-right" class="ms-2" />
        </li>
      </ul>
<!-- hide the button temporally https://optoro.atlassian.net/browse/RX-6246 -->
<!--      <button-->
<!--        class="nav-item-->
<!--                fw-semibold-->
<!--                text-primary-->
<!--                border-0-->
<!--                bg-transparent-->
<!--                p-3-->
<!--                d-flex-->
<!--                justify-content-between-->
<!--                align-items-center"-->
<!--        :disabled="disabled"-->
<!--        @click="addCategory"-->
<!--      >-->
<!--        <span>ADD CATEGORY</span>-->
<!--        <BootstrapIcon name="bs-icon-chevron-right" class="ms-2" />-->
<!--      </button>-->
    </div>
    <div class="d-flex flex-column px-5 flex-fill" v-if="selectedCategory">
      <div>
        <label class="form-label">Reason Category *</label>
        <div class="d-flex align-items-center justify-content-between">
          <div class="flex-fill">
            <label
              class="form-label"
              v-if="selectedCategory.fields.defaultCategoryEnabled.inputValue"
            >
              These reasons will be used for any item that doesn’t fall under a Reason Category.
            </label>
            <FormFieldInput
              type="text"
              class="form-control reason-name-input"
              placeholder="Add category name"
              :fieldModel="selectedCategory.fields.categoryName"
              :disabled="disabled || selectedCategory.fields.defaultCategoryEnabled.inputValue"
            />
          </div>
          <button
            type="button"
            class="btn btn-outline-primary ms-3"
            v-if="!selectedCategory.fields.defaultCategoryEnabled.inputValue"
            :disabled="disabled"
            @click="removeCategory(selectedCategoryIndex)"
          >
            REMOVE CATEGORY
          </button>
        </div>
        <FormFieldError :fieldModel="selectedCategory.fields.categoryName" />
      </div>
      <div>
        <ReturnReasonsTable
          :items="selectedCategory.fields.items.inputValue"
          :disabled="disabled"
          @add="onAddReturnReason"
          @update="onUpdateReturnReason"
          @remove="onRemoveReturnReason"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.nav-container .nav-item {
  cursor: pointer;
}

.reason-name-input {
  max-width: 250px;
}

.btn-link {
  text-decoration: none;
}
</style>
