<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import { getPortalUrl } from '@/utils/portal'
import { getFileUrl } from '@/utils/file'

const { selectedPortal } = storeToRefs(usePortalsStore())

const portalUrl = computed(() => {
  return getPortalUrl(selectedPortal.value.fields.portalUrlPrefix.inputValue)
})

const favIconUrl = computed(() => {
  const { fields } = selectedPortal.value
  if (fields.favicon.inputValue) {
    return getFileUrl(fields.favicon.inputValue)
  }
  return null
})
</script>

<template>
  <div class="browser-preview">
    <div class="browser-tabs-container">
      <div class="browser-dot-buttons-container">
        <div class="browser-dot-button red"></div>
        <div class="browser-dot-button yellow"></div>
        <div class="browser-dot-button green"></div>
      </div>
      <div class="browser-tab">
        <div class="d-flex align-items-center">
          <div class="favicon-container">
            <img v-if="favIconUrl" :src="favIconUrl" alt="">
          </div>
          <span>Look up order to start return</span>
        </div>
        <BootstrapIcon name="bs-icon-x" />
      </div>
    </div>
    <div class="browser-search-container">
      <div class="browser-nav-icon-container">
        <BootstrapIcon name="bs-icon-arrow-left" />
      </div>
      <div class="browser-nav-icon-container">
        <BootstrapIcon name="bs-icon-arrow-right" />
      </div>
      <div class="browser-nav-icon-container">
        <BootstrapIcon name="bs-icon-arrow-clockwise" />
      </div>
      <div class="browser-search-input">
        <BootstrapIcon name="bs-icon-lock-fill" />
        <div class="url">{{ portalUrl }}</div>
      </div>
    </div>
    <div class="w-100">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.browser-preview {
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #DBDCDD;

  .browser-tabs-container {
    width: 100%;
    height: 42px;
    background-color: #DEE1E6;
    display: flex;

    .browser-dot-buttons-container {
      display: flex;
      align-items: center;
      margin: 0 20px;

      .browser-dot-button {
        height: 13px;
        width: 13px;
        margin: 0px 4px;
        border-radius: 50%;
        display: inline-block;

        &.red {
          background-color: #FF5750;
        }

        &.yellow {
          background-color: #FFB937;
        }

        &.green {
          background-color: #1CC341;
        }
      }
    }

    .browser-tab {
      font-size: 12px;
      width: 240px;
      height: 34px;
      background-color: #FFFFFF;
      bottom: 0;
      padding-left: 15px;
      padding-right: 10px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      .favicon-container {
        width: 18px;
        height: 18px;
        margin-right: 8px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      svg {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }

  .browser-search-container {
    width: 100%;
    height: 42px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #DBDCDD;
    display: flex;
    align-items: center;
    padding: 0px 10px;

    .browser-nav-icon-container {
      margin: 0px 8px;

      svg {
        width: 20px !important;
        height: 20px !important;
        fill: #54585D !important;
      }
    }

    .browser-search-input {
      width: 70%;
      height: 35px;
      background-color: #F1F3F4;
      border-radius: 50px;
      margin-left: 4px;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 0px 10px;

      svg {
        width: 12px !important;
        height: 12px !important;
        fill: #54585D !important;
      }

      .url {
        margin-left: 10px;
      }
    }
  }
}
</style>
