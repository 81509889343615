<script setup>
import { ref, computed, onMounted } from 'vue'
import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.min.css'

const props = defineProps({ 
  modelValue: Object
})

const emit = defineEmits(['update:modelValue', 'onerror'])

const json = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

function onValidationError(errors) {
  emit('onerror', errors)
}

const container = ref(null)
const options = {
  mode: 'code',
  onChange: () => {
    const jsonText = editor.getText()
    try {
      json.value = jsonText.length ? JSON.parse(jsonText) : null
    } catch {
      // This error catch is just to avoid throwing an exception if JSON.parse fails
    }
  },
  onValidationError
}

let editor = null

onMounted(() => {
  editor = new JSONEditor(container.value, options, json.value)
})
</script>

<template>
  <div class="jsoneditor-container d-flex flex-column">
    <div ref="container" class="flex-fill pb-5"></div>
  </div>
</template>

<style lang="scss" scoped>
.jsoneditor-container {
  height: 100%;
}
</style>
