<script setup>
import CustomTooltip from '@/components/CustomTooltip.vue'

defineProps({
  name: String,
  url: String,
  badges: Array,
  disabled: Boolean,
  manageDisabled: Boolean
})

const emit = defineEmits(['click:manage'])

function onClickManage() {
  emit('click:manage')
}
</script>

<template>
  <div class="portal-item card shadow w-100">
    <div class="card-body">
      <div class="d-flex flex-fill justify-content-between">
        <div :class="{ 'text-disabled': disabled }">
          <h3 class="d-flex align-items-center">
            <span>{{ name }}</span>
            <div
              v-for="(badge, index) in badges" :key="index"
              class="badge-container ms-2 d-flex align-items-center justify-content-center"
            >
              <CustomTooltip
                v-if="badge.tooltip"
                placement="top"
                :title="badge.tooltip">
                <span :class="`badge rounded-pill text-bg-${badge.style} p-2`">
                  {{ badge.label }}
                </span>
              </CustomTooltip>
              <span
                v-else
                :class="`badge rounded-pill text-bg-${badge.style} p-2`"
              >
                {{ badge.label }}
              </span>
            </div>
          </h3>
          <div>
            <span class="fw-semibold">Portal URL:</span>&nbsp;{{ url }}
          </div>
        </div>
        <div>
          <button
            class="btn btn-primary px-4"
            type="button"
            :disabled="disabled || manageDisabled"
            @click="onClickManage"
          >
            MANAGE PORTAL
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.portal-item {
  height: 180px;

  .badge-container .badge {
    font-size: 12px;
  }
}
</style>