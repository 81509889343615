<script setup>
import { onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useClientsStore } from '@/stores/clients'

const router = useRouter()

const { clients } = storeToRefs(useClientsStore())

onMounted(() => {
  redirectToFirstClient(clients.value)
})

watch(clients, redirectToFirstClient)

function redirectToFirstClient(newClients) {
  if (newClients.length) {
    router.push({ name: 'clientDetail', params: { clientSlug: newClients[0].values.slug } })
  }
}
</script>

<template>
  <div></div>
</template>
