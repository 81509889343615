<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import CustomTabs from '@/components/CustomTabs.vue'
import CustomTab from '@/components/CustomTab.vue'
import TimeWindowsForm from '@/components/policies/TimeWindowsForm.vue'
import ReturnReasonsForm from '@/components/policies/ReturnReasonsForm.vue'
import { PORTAL_TAB_PATHS } from '@/constants.js'

const { selectedPortal } = storeToRefs(usePortalsStore())
</script>

<template>
  <CustomTabs>
    <CustomTab
      title="Time Windows"
      :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.TIME_WINDOWS)"
      :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.TIME_WINDOWS)"
    >
      <TimeWindowsForm />
    </CustomTab>
    <CustomTab
      title="Return Reasons"
      :draft="selectedPortal.hasChangesOn(PORTAL_TAB_PATHS.RETURN_REASONS)"
      :error="selectedPortal.hasErrorsOn(PORTAL_TAB_PATHS.RETURN_REASONS)"
    >
      <ReturnReasonsForm 
        :disabled="!selectedPortal.fields.returnReasons.editable"
      />
    </CustomTab>
  </CustomTabs>
</template>
