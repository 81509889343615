<script setup>
import { onMounted, ref } from 'vue'
import { Tooltip } from "bootstrap"

defineProps({
  title: String,
  placement: String
})

const tooltip = ref(null)

onMounted(() => {
  new Tooltip(tooltip.value)
})
</script>

<template>
  <a
    ref="tooltip"
    data-bs-toggle="tooltip"
    :data-bs-placement="placement"
    :data-bs-title="title"
  >
    <slot></slot>
  </a>
</template>

<style scoped>
a {
  display: flex;
  align-items: center;
}
</style>
