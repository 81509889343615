<script setup>
import { ref, provide } from 'vue'
import { findIndex } from 'lodash'
import DraftIcon from '@/components/DraftIcon.vue'
import ErrorIcon from '@/components/ErrorIcon.vue'

const tabs = ref([])
const activeTabHash = ref(null)

const props = defineProps({
  customClass: {
    type: String,
    default: 'primary'
  }
})

provide('addTab', (tab) => {
  const count = tabs.value.push(tab)

  if (count === 1) {
    activeTabHash.value = tab.hash
  }
})

provide('updateTab', (updatedTab) => {
  const index = findIndex(tabs.value, (tab) => tab.hash === updatedTab.hash)
  tabs.value.splice(index, 1, updatedTab)
})

provide('activeTabHash', activeTabHash)
</script>

<template>
  <ul class="nav nav-tabs" :class="`custom-tabs-${props.customClass}`">
    <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
      <a
        class="nav-link link-dark d-flex align-items-center"
        :class="{ 'active fw-bold': activeTabHash === tab.hash }"
        @click="activeTabHash = tab.hash"
      >
        <span class="title">{{ tab.title }}</span>
        <DraftIcon class="ms-2" v-if="!tab.error && tab.draft" />
        <ErrorIcon class="ms-2" v-if="tab.error" />
      </a>
    </li>
  </ul>
  <div class="d-flex flex-column flex-fill">
    <slot></slot>
  </div>
</template>
<style lang="scss" scoped>
.custom-tabs-secondary {
  --bs-nav-tabs-border-width: 0px;
  font-size: 12px;

  .nav-link.active {
    border-bottom-color: #FDC014;
    border-bottom-width: 4px;
  }

  .title {
    text-transform: uppercase;
  }
}
</style>
