<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldError from '@/components/FormFieldError.vue'
import FormFieldTextarea from '@/components/FormFieldTextarea.vue'
import HowItWorksModalPreview from '@/components/customize/HowItWorksModalPreview.vue'
import HomePreview from '@/components/customize/HomePreview.vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())
</script>

<template>
  <div class="row">
    <div class="col-4">
      <h3>“How It Works” Modal</h3>
      <p>Explain how your return process works. The explanation will appear when the shopper clicks the highlighted link.</p>
    </div>
    <div class="col-8">
      <HomePreview :howItWorksHighlighted="true"/>
    </div>
  </div>
  <hr class="border border-dark my-5">
  <div class="row">
    <div class="col-4">
      <div class="form-group">
      <label class="form-label">Header *</label>
      <FormFieldInput
        type="text"
        class="form-control"
        :fieldModel="selectedPortal.fields.howItWorksModalHeader"
        :disabled="isLoading"
      />
      <FormFieldError :fieldModel="selectedPortal.fields.howItWorksModalHeader" />
    </div>
    <br>
    <div class="form-group">
      <label class="form-label">Modal Copy (Optional)</label>
      <FormFieldTextarea
        class="form-control"
        :disabled="isLoading"
        :fieldModel="selectedPortal.fields.howItWorksModalBody"
        rows="10"
      />
      <FormFieldError :fieldModel="selectedPortal.fields.howItWorksModalBody" />
    </div>
    </div>
    <div class="col-8">
      <HowItWorksModalPreview />
    </div>
  </div>
</template>
