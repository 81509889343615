<script setup>
defineProps({
  messages: Array,
  type: String,
  closeable: Boolean
})

const emit = defineEmits(['close'])

function onClickClose() {
  emit('close')
}
</script>

<template>
  <div
    :class="`alert alert-${type} alert-dismissible fade show`"
    role="alert"
  >
    <ul v-if="messages.length > 1">
      <li v-for="(message, index) in messages" :key="index">{{ message }}</li>
    </ul>
    <div v-else>
      {{ messages[0] }}
    </div>
    <button
      v-if="closeable"
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
      @click="onClickClose"
    >
    </button>
  </div>
</template>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  padding-left: inherit;
}
</style>
