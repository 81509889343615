<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import ModalPreview from '@/components/customize/ModalPreview.vue'

const { selectedPortal } = storeToRefs(usePortalsStore())

const howItWorksModalBody = computed(() => {
  const { fields } = selectedPortal.value
  const stringValue = fields.howItWorksModalBody.inputValue
  if (stringValue) {
    return stringValue.replace(
      '$$refundTimingEstimate',
      fields.refundTimingEstimate.inputValue || '7'
    )
  }
  return null
})
</script>

<template>
  <ModalPreview :header="selectedPortal.fields.howItWorksModalHeader.inputValue">
    <p v-if="howItWorksModalBody" v-html="howItWorksModalBody" />
  </ModalPreview>
</template>
