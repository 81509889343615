<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import { getFileUrl } from '@/utils/file'
import optoroLogo from '@/assets/images/optoroLogo.png'

const { selectedPortal } = storeToRefs(usePortalsStore())

const showSitewideMessage = computed(() => {
  const { fields } = selectedPortal.value
  return fields.siteWideEnabled.inputValue && fields.siteWideMessage.inputValue?.length
})

const logoUrl = computed(() => {
  const { fields } = selectedPortal.value
  if (fields.mainLogo.inputValue) {
    return getFileUrl(fields.mainLogo.inputValue)
  }
  return null
})

const bodyFontFamily = computed(() => {
  const { fields } = selectedPortal.value
  return fields.bodyBrandFontFamily.inputValue
    ? fields.bodyBrandFontFamily.inputValue
    : fields.bodyWebFontFamily.inputValue
})

const poweredByText = computed(() => (
  selectedPortal.value?.values?.settings?.strings?.FOOTER?.POWERED_BY
))

const termsOfServiceText = computed(() => (
  selectedPortal.value?.values?.settings?.strings?.FOOTER?.TERMS_OF_SERVICE
))

const privacyPolicyText = computed(() => (
  selectedPortal.value?.values?.settings?.strings?.FOOTER?.PRIVACY_POLICY
))
</script>

<template>
  <div
    class="main-layout-preview d-flex flex-column"
    :style="{
      'font-family': bodyFontFamily,
      'color': selectedPortal.fields.bodyColor.inputValue
    }"
  >
    <div
      v-if="showSitewideMessage"
      class="sitewide-message"
      :style="{
        'background-color': selectedPortal.fields.siteWideBackgroundColor.inputValue,
        'color': selectedPortal.fields.siteWideTextColor.inputValue
      }"
    >
      {{ selectedPortal.fields.siteWideMessage.inputValue }}
    </div>
    <slot name="header">
      <div class="header">
        <img class="img-fluid" v-if="logoUrl" :src="logoUrl" alt="">
      </div>
    </slot>
    <div class="flex-fill d-flex flex-column">
      <slot></slot>
    </div>
    <div class="footer">
      <div class="d-flex align-items-center">
        {{ poweredByText }}&nbsp;
        <img
          :src="optoroLogo"
          class="img-fluid optoro-logo"
          alt="Optoro"
        >
      </div>
      <div>
        <span class="me-2">{{ termsOfServiceText }}</span>
        <span class="ms-2">{{ privacyPolicyText }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-layout-preview {
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #DBDCDD;
  font-family: sans-serif;

  .sitewide-message {
    padding: 10px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    background-color: #6D6D6D;
    color: white;
  }

  .header {
    padding-top: 1.6rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #DBDCDD;
    height: 4.5em;

    img {
      max-height: 50px;
      max-width: 200px;
    }
  }

  .footer {
    border-top: 1px solid #DBDCDD;
    width: 100%;
    height: 80px;
    font-size: 12px;
    color: #919191;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .optoro-logo {
      max-width: 50px;
    }
  }
}
</style>
