<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import { capitalize } from 'lodash'

const props = defineProps({
  label: {
    type: String,
    default: 'Label'
  },
  type: {
    type: String,
    default: 'primary'
  },
  state: {
    type: String,
    default: 'active'
  }
})

const { selectedPortal } = storeToRefs(usePortalsStore())

const radius = computed(() => selectedPortal.value.fields.buttonRadius.inputValue || 0)

const textTransform = computed(() => (
  selectedPortal.value.fields.buttonTextUppercaseEnabled.inputValue ? 'uppercase' : 'none'
))

const fontFamily = computed(() => (
  selectedPortal.value.fields.bodyBrandFontFamily.inputValue || selectedPortal.value.fields.bodyWebFontFamily.inputValue
))

const backgroundColor = computed(() => (
  selectedPortal.value.fields[`button${capitalize(props.type)}${capitalize(props.state)}BackgroundColor`]?.inputValue
))

const borderColor = computed(() => (
  selectedPortal.value.fields[`button${capitalize(props.type)}${capitalize(props.state)}BorderColor`]?.inputValue
))

const textColor = computed(() => (
  selectedPortal.value.fields[`button${capitalize(props.type)}${capitalize(props.state)}TextColor`]?.inputValue
))

const inlineStyle = computed(() => {
  return {
    'border-radius': `${radius.value}px`,
    'text-transform': textTransform.value,
    'font-family': fontFamily.value,
    'background-color': backgroundColor.value,
    'border-color': borderColor.value,
    'color': textColor.value
  }
})
</script>

<template>
  <div :class="`button-preview ${type}-${state}`" :style="inlineStyle">
    {{ label }}
  </div>
</template>

<style lang="scss" scoped>
.button-preview {
  width: 100%;
  font-size: 15px;
  border-style: solid;
  border-width: .2px;
  padding: 14px;
  text-align: center;
}

.primary-active {
  color: #fff;
  background-color: #385ADC;
  border-color: #385ADC;
  border: none;
}
.secondary-active {
  color: #3356e4;
  background-color: #fff;
  border-color: #3356e4;
  font-weight: 700;
}
.tertiary-active {
  color: #385ADC;
  background-color: transparent !important;
  border-color: transparent !important;
  padding-left: 0px;
  padding-right: 0px;
}

.primary-hover {
  color: #fff;
  background-color: #2D4ABA;
  border-color: #2D4ABA;
  border: none;
}
.secondary-hover {
  color: #385ADC;
  background-color: #385ADC20;
  border-color: #385ADC;
  font-weight: 700;
}
.tertiary-hover {
  color: #385ADC;
  background-color: transparent !important;
  border-color: transparent !important;
  text-decoration: underline;
  padding-left: 0px;
  padding-right: 0px;
}

.primary-disabled {
  color: #a7a7a7;
  background-color: #e9e9e9;
  border-color: #e9e9e9;
  border: none;
}
.secondary-disabled {
  color: #a7a7a7;
  background-color: #a7a7a720;
  border-color: #a7a7a7;
  font-weight: 700;
}
.tertiary-disabled {
  color: #a7a7a7;
  background-color: transparent !important;
  border-color: transparent !important;
  padding-left: 0px;
  padding-right: 0px;
}
</style>
