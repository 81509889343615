<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import HomePreview from '@/components/customize/HomePreview.vue'
import { computed } from 'vue'
import FormFieldCheckbox from '@/components/FormFieldCheckbox.vue'
import FormFieldTextarea from '@/components/FormFieldTextarea.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldError from '@/components/FormFieldError.vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())

function onChangeSiteWideEnabled() {
  const { fields } = selectedPortal.value
  fields.siteWideMessage.inputValue = null
  fields.siteWideTextColor.inputValue = null
  fields.siteWideBackgroundColor.inputValue = null
}
const isSiteWideDisabled = computed(() => {
  return isLoading.value || !selectedPortal.value.fields.siteWideEnabled.inputValue
})
</script>

<template>
  <div class="row">
    <div class="col-4">
      <div class="form-check mt-3">
        <FormFieldCheckbox
          class="form-check-input"
          role="switch"
          :fieldModel="selectedPortal.fields.siteWideEnabled"
          :disabled="isLoading"
          @change="onChangeSiteWideEnabled"
        />
        <h3>Sitewide Message</h3>
      </div>
      <div>
        This banner allows you to create a persistent message <br> across the portal. It will be visible on all pages.
      </div>
      <br><br>
      <div class="form-group">
        <label for="siteWideMessage1" class="form-label">
          Sitewide message
          <span v-if="selectedPortal.fields.siteWideEnabled.inputValue">*</span>
        </label>
        <FormFieldTextarea
          name="siteWideMessage"
          id="siteWideMessage1"
          ref="message"
          class="form-control"
          :disabled="isSiteWideDisabled"
          :fieldModel="selectedPortal.fields.siteWideMessage"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.siteWideMessage" />
      </div>
      <br>
      <div class="form-group">
        <label class="form-label">
          Text Color (Hex Code Value)
          <span v-if="selectedPortal.fields.siteWideEnabled.inputValue">*</span>
        </label>
        <FormFieldInput
          type="text"
          class="form-control"
          :fieldModel="selectedPortal.fields.siteWideTextColor"
          :disabled="isSiteWideDisabled"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.siteWideTextColor" />
      </div>
      <br>
      <div class="form-group">
        <label class="form-label">
          Background Color (Hex Code Value)
          <span v-if="selectedPortal.fields.siteWideEnabled.inputValue">*</span>
        </label>
        <FormFieldInput
          type="text"
          class="form-control"
          :fieldModel="selectedPortal.fields.siteWideBackgroundColor"
          :disabled="isSiteWideDisabled"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.siteWideBackgroundColor" />
      </div>
    </div>
    <div class="col-8">
      <HomePreview />
    </div>
  </div>
</template>
