import { object, string, boolean } from 'yup'
import { HEX_COLOR_REGEX } from '@/constants'
import { numberSchema } from '@/validation_schemas/common'

const colorSchema = string()
  .matches(HEX_COLOR_REGEX, 'Enter a valid hexadecimal code (example: #385ADC)')
  .required()

export default object({
  button_rounded_enabled: boolean(),
  button_radius: numberSchema
    .label('Radius')
    .integer()
    .when('button_rounded_enabled', {
        is: true,
        then: schema => schema.required().moreThan(0),
        otherwise: schema => schema.nullable()
    }),
  button_text_uppercase_enabled: boolean(),
  // Primary Button
  button_primary_active_background_color: colorSchema.label('Primary Button Active Background'),
  button_primary_active_text_color: colorSchema.label('Primary Button Active Text'),
  button_primary_hover_background_color: colorSchema.label('Primary Button Hover Background'),
  button_primary_hover_text_color: colorSchema.label('Primary Button Hover Text'),
  button_primary_disabled_background_color: colorSchema.label('Primary Button Disabled Background'),
  button_primary_disabled_text_color: colorSchema.label('Primary Button Disabled Text'),
  // Secondary Button
  button_secondary_active_background_color: colorSchema.label('Secondary Button Active Background'),
  button_secondary_active_border_color: colorSchema.label('Secondary Button Active Border'),
  button_secondary_active_text_color: colorSchema.label('Secondary Button Active Text'),
  button_secondary_hover_background_color: colorSchema.label('Secondary Button Hover Background'),
  button_secondary_hover_border_color: colorSchema.label('Secondary Button Hover Border'),
  button_secondary_hover_text_color: colorSchema.label('Secondary Button Hover Text'),
  button_secondary_disabled_background_color: colorSchema.label('Secondary Button Disabled Background'),
  button_secondary_disabled_border_color: colorSchema.label('Secondary Button Disabled Border'),
  button_secondary_disabled_text_color: colorSchema.label('Secondary Button Disabled Text'),
  // Tertiary Button
  button_tertiary_active_text_color: colorSchema.label('Tertiary Button Active Text'),
  button_tertiary_hover_text_color: colorSchema.label('Tertiary Button Hover Text'),
  button_tertiary_disabled_text_color: colorSchema.label('Tertiary Button Disabled Text')
})
