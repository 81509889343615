<script setup>
import { onMounted, ref } from "vue"
import { Modal } from "bootstrap"

defineProps({
  title: {
    type: String
  }
})

let modalElement = ref(null)
let thisModalObj = null

onMounted(() => {
  thisModalObj = new Modal(modalElement.value)
})

function show() {
  thisModalObj.show()
}

function hide() {
  thisModalObj.hide()
}

defineExpose({ show, hide })
</script>

<template>
  <div class="modal fade" tabindex="-1" aria-labelledby=""
       aria-hidden="true" ref="modalElement">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">{{ title }}</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
      </div>
    </div>
  </div>
</template>