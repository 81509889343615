<script setup>
import { onMounted, ref, toRaw } from 'vue'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { object, reach } from 'yup'
import { useUserStore } from '@/stores/user'
import { useClientsStore } from '@/stores/clients'
import { usePortalsStore } from '@/stores/portals'
import { buildFormState } from '@/utils/form'
import { getPortalUrlSuffix, getPortalUrl, isReady } from '@/utils/portal'
import portalValidationSchema from '@/validation_schemas/portal'
import portalFieldsMapping from '@/stores/portals/fields_mapping.js'
import BaseModal from '@/components/BaseModal.vue'
import PortalListItem from '@/components/PortalListItem.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldError from '@/components/FormFieldError.vue'
import AlertMessage from '@/components/AlertMessage.vue'

const router = useRouter()
const portalsStore = usePortalsStore()

const { canCreatePortal } = storeToRefs(useUserStore())
const { selectedClient, selectedClientValid } = storeToRefs(useClientsStore())
const { isLoading, isSaving, portals } = storeToRefs(portalsStore)

onMounted(() => {
  portalsStore.setSelectedPortalIndex(null)
})

const createPortalForm = ref(buildFormState({
  validationSchema: object({
    identifier: reach(portalValidationSchema, 'identifier'),
    settings: object({
      policies: object({
        retailer_info: object( {
          name: reach(portalValidationSchema, 'settings.policies.retailer_info.name'),
        }),
      }),
      config: object({
        urlPrefix: reach(portalValidationSchema, 'settings.config.urlPrefix')
      })
    })
  }),
  initialValues: {},
  fieldsMapping: portalFieldsMapping
}))

const createPortalModal = ref(null)

function showCreatePortalModal(){
  resetCreatePortalModal()
  if (selectedClient.value && portals.value.length === 0) {
    const { name, slug } = selectedClient.value.values
    createPortalForm.value.fields.retailerName.inputValue = name
    createPortalForm.value.fields.identifier.inputValue = slug
    createPortalForm.value.fields.portalUrlPrefix.inputValue = slug
  }
  createPortalModal.value.show()
}

function hideCreatePortalModal() {
  createPortalModal.value.hide()
  resetCreatePortalModal()
}

function resetCreatePortalModal() {
  createPortalForm.value.reset()
}

async function createPortal() {
  const { values, validate } = createPortalForm.value
  if (validate()) {
    await portalsStore.createPortal({
      ...toRaw(values),
      client_id: selectedClient.value.values.id
    })
    hideCreatePortalModal()
  }
}

function onSelectPortal({ values }) {
  const { slug } = selectedClient.value.values
  const { identifier } = values
  router.push({
    name: 'portalDetail',
    params: {
      clientSlug: slug,
      portalIdentifier: identifier
    }
  })
}

function portalBadges(portal) {
  if (!isReady(portal)) {
    return [
      {
        label: 'ACTION REQUIRED',
        style: 'warning',
        tooltip: 'To complete portal creation, you must submit a ticket per runbook instructions. Once the ticket is completed, the portal will be ready to manage.'
      }
    ]
  }

  return []
}
</script>

<template>
  <div class="d-flex flex-column vh-100">
    <Transition name="fade">
      <AlertMessage
        v-if="selectedClient && !selectedClientValid && canCreatePortal"
        :messages="['Please configure all Client Settings in order to create a portal.']"
        type="warning"
        :closeable="false"
      />
    </Transition>
    <div class="d-flex flex-column flex-fill px-6 py-4">
      <div class="d-flex flex-column align-items-start">
        <h1>Portal Admin</h1>
        <router-link
          :to="{ name: 'clientSettings' }"
          class="fw-semibold client-settings-link"
        >
          MANAGE CLIENT SETTINGS
        </router-link>
      </div>
      <div class="container my-5">
        <div v-if="!isLoading && portals.length === 0" class="fst-italic text-left">
          The customer does not have any portals yet.
        </div>
        <div v-if="isLoading" class="d-flex justify-content-center">
          <span class="spinner-border text-primary" role="status"></span>
        </div>
        <div
          v-else
          v-for="(portal, index) in portals"
          :key="index"
          class="mt-3"
        >
          <PortalListItem
            :name="portal.fields.retailerName.inputValue"
            :url="getPortalUrl(portal.fields.portalUrlPrefix.inputValue)"
            :badges="portalBadges(portal)"
            :disabled="false"
            :manage-disabled="!isReady(portal)"
            @click:manage="onSelectPortal(portal)"
          />
        </div>
        <div class="mt-4">
          <button
            type="button"
            class="btn btn-outline-primary px-4"
            @click="showCreatePortalModal"
            :disabled="isLoading || !canCreatePortal || !selectedClientValid"
          >
            CREATE PORTAL
          </button>
        </div>
      </div>
      <BaseModal title="Create Portal" ref="createPortalModal">
        <template #body>
          <div class="container">
            <br>
            <div class="mt-3">
              <label class="form-label">Brand Name</label>
              <FormFieldInput
                type="text"
                class="form-control"
                :fieldModel="createPortalForm.fields.retailerName"
                :disabled="isLoading"
              />
              <FormFieldError :fieldModel="createPortalForm.fields.retailerName" />
            </div>
            <div class="mt-3">
              <label class="form-label">Portal ID</label>
              <FormFieldInput
                type="text"
                class="form-control"
                :fieldModel="createPortalForm.fields.identifier"
                :disabled="isLoading"
              />
              <FormFieldError :fieldModel="createPortalForm.fields.identifier" />
            </div>
            <div class="mt-3">
              <label class="form-label">Portal URL</label>
              <div class="input-group">
                <FormFieldInput
                  type="text"
                  class="form-control"
                  :fieldModel="createPortalForm.fields.portalUrlPrefix"
                  :disabled="isLoading"
                />
                <span class="input-group-text" id="basic-addon2">.{{ getPortalUrlSuffix() }}</span>
              </div>
              <FormFieldError :fieldModel="createPortalForm.fields.portalUrlPrefix" />
            </div>
            <div class="mt-5 mb-4">
              <button
                type="button"
                class="btn btn-primary px-4"
                @click="createPortal"
                :disabled="isLoading || !createPortalForm.valid"
              >
                <span v-if="isSaving" class="spinner-border spinner-border-sm me-1"></span>
                CREATE PORTAL
              </button>
            </div>
          </div>
        </template>
      </BaseModal>
    </div> 
  </div>
</template>

<style scoped>
.client-settings-link {
  text-decoration: none;
}
</style>
