<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import FormFieldCheckbox from '@/components/FormFieldCheckbox.vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())
</script>

<template>
  <div>
    <label class="form-label">The following is available in your Returns Portal (Optional):</label>
    <div class="form-check mt-2">
      <FormFieldCheckbox
        class="form-check-input"
        role="switch"
        id="instantExchanges"
        :fieldModel="selectedPortal.fields.instantExchangesEnabled"
        :disabled="isLoading"
      />
      <label class="form-check-label" for="instantExchanges">Instant Exchanges</label>
    </div>
    <div class="form-check mt-2">
       <FormFieldCheckbox
          class="form-check-input"
          role="switch"
          id="standardExchanges"
          :fieldModel="selectedPortal.fields.standardExchangesEnabled"
          :disabled="isLoading"
       />
      <label class="form-check-label" for="standardExchanges">Ship-on-receipt Exchanges</label>
    </div>
    <div class="form-check mt-2">
      <FormFieldCheckbox
        class="form-check-input"
        role="switch"
        id="instantGiftCards"
        :fieldModel="selectedPortal.fields.instantGiftCardsEnabled"
        :disabled="isLoading"
      />
      <label class="form-check-label" for="instantGiftCards">Instant Store Credit</label>
    </div>
  </div>
</template>
