import { object, string, boolean } from 'yup'
import { HEX_COLOR_REGEX } from '@/constants'

export default object({
  enabled: boolean(),
  text_color: string()
    .label('Text color')
    .when('enabled', {
        is: true,
        then: schema => schema
            .matches(HEX_COLOR_REGEX, 'Enter a valid hexadecimal code (example: #385ADC)')
            .required(),
        otherwise: schema => schema.nullable()
    }),
  background_color: string()
    .label('Background color')
    .when('enabled', {
        is: true,
        then: schema => schema
            .matches(HEX_COLOR_REGEX, 'Enter a valid hexadecimal code (example: #385ADC)')
            .required(),
        otherwise: schema => schema.nullable()
    }),
  message: string()
    .label('Sitewide message')
    .when('enabled', {
        is: true,
        then: schema => schema.required(),
        otherwise: schema => schema.nullable()
    })
})
