<script setup>
import { inject, ref, onBeforeMount, watch } from 'vue'

const addTab = inject('addTab')
const updateTab = inject('updateTab')
const activeTabHash = inject('activeTabHash')

const props = defineProps({
  title: String,
  draft: Boolean,
  error: Boolean,
  showHeader: {
    type: Boolean,
    default: true
  }
})

const hash = ref(null)
const isActive = ref(false)

function buildTabItem() {
  return {
    title: props.title,
    draft: props.draft,
    error: props.error,
    hash: hash.value
  }
}

onBeforeMount(() => {
  hash.value = `#${props.title.toLowerCase().replace(/ /g, '-')}`
  addTab(buildTabItem())
})

watch(() => props.draft, () => {
  updateTab(buildTabItem())
})

watch(() => props.error, () => {
  updateTab(buildTabItem())
})

watch(activeTabHash, () => {
  isActive.value = activeTabHash.value === hash.value
})
</script>

<template>
  <div class="tab mt-4 flex-fill" v-show="isActive">
    <h3 v-if="showHeader" class="mb-4">{{ title }}</h3>
    <slot></slot>
  </div>
</template>
