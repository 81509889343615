<script setup>
import { storeToRefs } from 'pinia'
import { useClientsStore } from '@/stores/clients'
import { useRouter } from 'vue-router'
import CustomTabs from '@/components/CustomTabs.vue'
import CustomTab from '@/components/CustomTab.vue'
import OrderForm from '@/components/OrderForm.vue'
import ExpressReturnsForm from '@/components/ExpressReturnsForm.vue'
import WarehouseReceivingForm from '@/components/WarehouseReceivingForm.vue'

const clientsStore = useClientsStore()
const router = useRouter()

const { isSaving, selectedClient, canSave } = storeToRefs(clientsStore)

async function save() {
  if (await clientsStore.saveSelectedClient()) {
    router.push({
      name: 'portalList',
      params: { clientSlug: selectedClient.value.values.slug }
    })
  }
}
</script>

<template>
  <div class="d-flex flex-column flex-fill px-6 py-4">
    <div class="d-flex align-items-center justify-content-between">
      <h1>Portal Admin</h1>
      <div>
        <router-link
          :to="{ name: 'portalList' }"
          class="btn btn-outline-primary"
        >
          CANCEL
        </router-link>
        <button
          class="btn btn-primary ms-3"
          type="button"
          :disabled="!canSave"
          @click="save()"
        >
          <span v-if="isSaving" class="spinner-border spinner-border-sm me-1"></span>
          SAVE
        </button>
      </div>
    </div>
    <h3 class="mt-4">Client Settings</h3>
    <div>* indicates a required field. Configure these settings to create the client in the Portal Admin.</div>
    <div class="mt-4" v-if="selectedClient">
      <CustomTabs>
        <CustomTab
            title="Orders"
            :draft="selectedClient.hasChangesOn(['settings.order'])"
            :error="selectedClient.hasErrorsOn(['settings.order'])"
        >
          <OrderForm/>
        </CustomTab>
        <CustomTab
          title="Express Returns"
          :draft="selectedClient.hasChangesOn(['settings.express_returns'])"
          :error="selectedClient.hasErrorsOn(['settings.express_returns'])"
        >
          <ExpressReturnsForm />
        </CustomTab>
        <CustomTab
          title="Warehouse Receiving"
          :draft="selectedClient.hasChangesOn(['settings.warehouse_receiving'])"
          :error="selectedClient.hasErrorsOn(['settings.warehouse_receiving'])"
        >
          <WarehouseReceivingForm />
        </CustomTab>
      </CustomTabs>
    </div>
  </div>
</template>
