<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import HomePreview from '@/components/customize/HomePreview.vue'
import { getImagesLocation } from '@/utils/portal'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldError from '@/components/FormFieldError.vue'
import FormFieldImage from '@/components/FormFieldImage.vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())
</script>

<template>
  <div class="row">
    <div class="col-4">
      <fieldset>
        <h3>Order Lookup Image</h3>
        <p>
          1200x900 px in png file format recommended. <br>Max file size 2MB.
        </p>
        <div class="my-3">
          <FormFieldError :fieldModel="selectedPortal.fields.orderLookupImage" />
        </div>
        <FormFieldImage
          :fieldModel="selectedPortal.fields.orderLookupImage"
          name="hero"
          :location="getImagesLocation(selectedPortal)"
          :disabled="isLoading"
          :maxSize="2000"
        />
      </fieldset>
      <fieldset class="mt-5 w-75">
        <h3>Language Content</h3>
        <div>
          <label class="form-label">Header *</label>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.orderLookupHeader"
            :disabled="isLoading"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.orderLookupHeader" />
        </div>
        <div class="mt-3">
          <label class="form-label">Input label *</label>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.orderLookupInputLabel"
            :disabled="isLoading"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.orderLookupInputLabel" />
        </div>
        <div class="mt-3">
          <label class="form-label">Input hint text *</label>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.orderLookupInputPlaceholder"
            :disabled="isLoading"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.orderLookupInputPlaceholder" />
        </div>
        <div class="mt-3">
          <label class="form-label">Button label *</label>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.orderLookupButtonText"
            :disabled="isLoading"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.orderLookupButtonText" />
        </div>
      </fieldset>
    </div>
    <div class="col-8">
      <HomePreview />
    </div>
  </div>
</template>
