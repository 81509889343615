<script setup>
import { storeToRefs } from 'pinia'
import { useClientsStore } from '@/stores/clients'
import { RMA_ADAPTER_SUPPORTED_VERSIONS } from '@/constants'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldError from '@/components/FormFieldError.vue'
import FormFieldSelect from '@/components/FormFieldSelect.vue'
import FormFieldCheckbox from '@/components/FormFieldCheckbox.vue'
import FormFieldNumber from '@/components/FormFieldNumber.vue'

const { isLoading, isSaving, selectedClient } = storeToRefs(useClientsStore())

function clearReturnsManagementInputs() {
  const { fields } = selectedClient.value
  fields.returnsManagementReceivingProgram.inputValue = undefined
  fields.returnsManagementAutoCloseRmasAfterDays.inputValue = undefined
}

function clearThirdPartyLogisticsInputs() {
  const { fields } = selectedClient.value
  fields.thirdPartyLogisticsClientSlug.inputValue = undefined
  fields.thirdPartyLogisticsReceivingProgram.inputValue = undefined
}

function clearCrossdockInputs() {
  const { fields } = selectedClient.value
  fields.crossdockReceivingProgram.inputValue = undefined
}
</script>

<template>
  <fieldset>
    <div class="form-group col-2">
      <label class="form-label">Warehouse ID *</label>
      <FormFieldInput
        type="text"
        class="form-control"
        :fieldModel="selectedClient.fields.warehouseId"
        :disabled="isLoading || isSaving"
      />
      <FormFieldError :fieldModel="selectedClient.fields.warehouseId" />
    </div>
    <div class="form-group col-2">
      <label class="form-label">Client ID *</label>
      <FormFieldInput
        type="text"
        class="form-control"
        :fieldModel="selectedClient.fields.clientId"
        :disabled="isLoading || isSaving"
      />
      <FormFieldError :fieldModel="selectedClient.fields.clientId" />
    </div>
    <div class="form-group col-2">
      <label class="form-label">RMA Adapter *</label>
      <FormFieldSelect
        class="form-select"
        :fieldModel="selectedClient.fields.rmaAdapterVersion"
        :disabled="isLoading || isSaving"
      >
        <option :value="null">Select Version</option>
        <option
          v-for="(version, index) in RMA_ADAPTER_SUPPORTED_VERSIONS"
          :key="index"
          :value="version"
        >
          {{ `Version ${version}` }}
        </option>
      </FormFieldSelect>
      <FormFieldError :fieldModel="selectedClient.fields.rmaAdapterVersion" />
    </div>
  </fieldset>
  <fieldset class="mt-5">
    <div class="form-check">
      <FormFieldCheckbox 
        class="form-check-input"
        :fieldModel="selectedClient.fields.returnsManagementEnabled"
        :disabled="isLoading || isSaving"
        @change="clearReturnsManagementInputs"
      />
      <label class="form-check-label">Use Returns Management (RM) (Optional)</label>
    </div>
    <div class="form-group col-2 mt-2">
      <label class="form-label">Receiving Program</label>
      <FormFieldInput
        type="text"
        class="form-control"
        :fieldModel="selectedClient.fields.returnsManagementReceivingProgram"
        :disabled="isLoading || isSaving || !selectedClient.fields.returnsManagementEnabled.inputValue"
      />
      <FormFieldError :fieldModel="selectedClient.fields.returnsManagementReceivingProgram" />
    </div>
    <div class="form-group col-2 mt-3">
      <div class="d-flex align-items-center">
        Auto-close RMAs after
        <FormFieldNumber
          class="form-control number-input mx-2"
          placeholder="0"
          :fieldModel="selectedClient.fields.returnsManagementAutoCloseRmasAfterDays"
          :disabled="isLoading || isSaving || !selectedClient.fields.returnsManagementEnabled.inputValue"
        />
        days
      </div>
      <FormFieldError :fieldModel="selectedClient.fields.returnsManagementAutoCloseRmasAfterDays" />
    </div>
  </fieldset>
  <fieldset class="mt-5">
    <div class="form-check">
      <FormFieldCheckbox 
        class="form-check-input"
        :fieldModel="selectedClient.fields.thirdPartyLogisticsEnabled"
        :disabled="isLoading || isSaving"
        @change="clearThirdPartyLogisticsInputs"
      />
      <label class="form-check-label">Use 3PL client (Optional)</label>
    </div>
    <div class="form-group col-2 mt-3">
      <label class="form-label">Client Slug</label>
      <FormFieldInput
        type="text"
        class="form-control"
        :fieldModel="selectedClient.fields.thirdPartyLogisticsClientSlug"
        :disabled="isLoading || isSaving || !selectedClient.fields.thirdPartyLogisticsEnabled.inputValue"
      />
      <FormFieldError :fieldModel="selectedClient.fields.thirdPartyLogisticsClientSlug" />
    </div>
    <div class="form-group col-2 mt-2">
      <label class="form-label">Receiving Program</label>
      <FormFieldInput
        type="text"
        class="form-control"
        :fieldModel="selectedClient.fields.thirdPartyLogisticsReceivingProgram"
        :disabled="isLoading || isSaving || !selectedClient.fields.thirdPartyLogisticsEnabled.inputValue"
      />
      <FormFieldError :fieldModel="selectedClient.fields.thirdPartyLogisticsReceivingProgram" />
    </div>
  </fieldset>
  <fieldset class="mt-5">
    <div class="form-check">
      <FormFieldCheckbox 
        class="form-check-input"
        :fieldModel="selectedClient.fields.crossdockEnabled"
        :disabled="isLoading || isSaving"
        @change="clearCrossdockInputs"
      />
      <label class="form-check-label">Use Crossdock (Optional)</label>
    </div>
    <div class="form-group col-2 mt-2">
      <label class="form-label">Receiving Program</label>
      <FormFieldInput
        type="text"
        class="form-control"
        :fieldModel="selectedClient.fields.crossdockReceivingProgram"
        :disabled="isLoading || isSaving || !selectedClient.fields.crossdockEnabled.inputValue"
      />
      <FormFieldError :fieldModel="selectedClient.fields.crossdockReceivingProgram" />
    </div>
  </fieldset>
</template>

<style lang="scss" scoped>
.number-input {
  width: 72px;
  text-align: center;
}
</style>
