import { object, string, boolean } from 'yup'
import { RMA_ADAPTER_SUPPORTED_VERSIONS } from '@/constants'
import { numberSchema, decimalSchema } from '@/validation_schemas/common'

export default object({
  settings: object({
    order: object({
      pull_upc_enabled: boolean(),
      pull_image_url_enabled: boolean(),
      pull_variants_enabled: boolean(),
      pull_title_enabled: boolean(),
      pull_dimensions_enabled: boolean(),
      final_sale_tag_enabled: boolean(),
      shipping_rate_override_enabled: boolean(),
      third_party_tag_enabled: boolean(),
      return_window_eligibility_enabled: boolean()
    }),
    express_returns: object({
      return_quantity_threshold: numberSchema
        .integer()
        .positive()
        .required()
        .label('Maximum number of items to return per RMA'),
      return_price_threshold: decimalSchema
        .required()
        .label('Maximum amount in total value to return per RMA')
    }),
    warehouse_receiving: object({
      warehouse_id: string().label('Warehouse ID').required(),
      client_id: string().label('Client ID').required(),
      rma_adapter_version: numberSchema
        .label('RMA Adapter Version')
        .required()
        .oneOf(RMA_ADAPTER_SUPPORTED_VERSIONS),
      returns_management_enabled: boolean(),
      returns_management_receiving_program: string()
        .label('Receiving Program')
        .when('returns_management_enabled', {
          is: true,
          then: schema => schema.required(),
          otherwise: schema => schema.nullable()
        }),
      returns_management_auto_close_rmas_after_days: numberSchema.
        label('Auto-close RMAs after days')
        .when('returns_management_enabled', {
          is: true,
          then: schema => schema.required(),
          otherwise: schema => schema.nullable()
        }),
      third_party_logistics_enabled: boolean(),
      third_party_logistics_client_slug: string()
        .label('Client Slug')
        .when('third_party_logistics_enabled', {
          is: true,
          then: schema => schema.required(),
          otherwise: schema => schema.nullable()
        }),
      third_party_logistics_receiving_program: string()
        .label('Receiving Program')
        .when('third_party_logistics_enabled', {
          is: true,
          then: schema => schema.required(),
          otherwise: schema => schema.nullable()
        }),
      crossdock_enabled: boolean(),
      crossdock_receiving_program: string()
        .label('Receiving Program')
        .when('crossdock_enabled', {
          is: true,
          then: schema => schema.required(),
          otherwise: schema => schema.nullable()
        })
    })
  })
})
