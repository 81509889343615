<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import BrowserPreview from '@/components/customize/BrowserPreview.vue'
import MainLayoutPreview from '@/components/customize/MainLayoutPreview.vue'
import imgPaymentCard from '@/assets/images/payment-card.png'
import ButtonPreview from '@/components/customize/ButtonPreview.vue'

const { selectedPortal } = storeToRefs(usePortalsStore())

const bodyFontFamily = computed(() => {
  const { fields } = selectedPortal.value
  return fields.bodyBrandFontFamily.inputValue
    ? fields.bodyBrandFontFamily.inputValue
    : fields.bodyWebFontFamily.inputValue
})

const bodyColor = computed(() => {
  return selectedPortal.value.fields.bodyColor.inputValue
})

const subHeaderFontFamily = computed(() => {
  const { fields } = selectedPortal.value

  if (fields.subHeaderBrandFontFamily.inputValue) {
    return fields.subHeaderBrandFontFamily.inputValue
  }

  return fields.subHeaderWebFontFamily.inputValue || bodyFontFamily.value
})

const headerColor = computed(() => {
  return selectedPortal.value.fields.headerColor.inputValue || bodyColor.value
})

const shippingFeeLabel = computed(() => {
  const text = selectedPortal.value.fields.returnSummaryShippingFeeLabel.inputValue
  const length = 30
  return text.length > length ? text.slice(0, length) : text
})

const returnReviewStrings = computed(() => (
  selectedPortal.value?.values?.settings?.strings?.RETURN_REVIEW || {}
))

const refundTimingText = computed(() => (
  (returnReviewStrings.value.ESTIMATED_REFUND_TIMING_TITLE || '').replace(
    '$$refundTimingEstimate',
    selectedPortal.value.fields.refundTimingEstimate.inputValue
  )
))
</script>

<template>
  <BrowserPreview>
    <MainLayoutPreview>
      <template #header>
        <div/>
      </template>
      <div class="return-summary-preview d-flex flex-column align-items-center py-4">
        <div class="d-flex flex-column">
          <div
            class="flex-fill align-items-start subheader"
            :style="{
              'font-family': subHeaderFontFamily,
              'color': headerColor
            }"
          >
            {{ returnReviewStrings.RETURN_SUMMARY }}
          </div>
          <div class="d-flex w-100">
            <div class="flex-fill">
              <div>
                {{ returnReviewStrings.RETURN_SUB_TOTAL }}
              </div>
              <div
                class="mt-1"
                :class="shippingFeeLabel ? 'shippingFeeLabel' : ''"
              >
                {{ shippingFeeLabel }}
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <div>$956.83</div>
              <div class="mt-1">($0.00)</div>
            </div>
          </div>
          <div class="flex-fill"><hr></div>
          <div class="d-flex w-100">
            <div class="flex-fill">
              <div>
                {{ returnReviewStrings.TOTAL_ESTIMATED_REFUND }}
              </div>
              <div class="giftCardTitle mt-3" v-html="refundTimingText">
              </div>
            </div>
            <div class="d-flex flex-column align-items-end">
              <div class="price">$956.83</div>
              <div class="mt-2">
                Original Payment Method
                <img alt="" :src="imgPaymentCard">
              </div>
            </div>
          </div>
          <div class="flex-fill"><hr></div>
          <div class="flex-fill termsTitle">
            {{ returnReviewStrings.RETURN_REVIEW_CONFIRM_MESSAGE1 }} {{returnReviewStrings.I_AGREE }} <u>{{ returnReviewStrings.TERMS }}</u> {{ returnReviewStrings.AND }} <u>{{ returnReviewStrings.PRIVACY_POLICY }}</u>
          </div>
          <div class="mt-2 button-preview-container">
            <ButtonPreview
              :label="returnReviewStrings.CONFIRM_BUTTON"
              type="primary"
              state="active"
              padding="8px 14px"
            />
          </div>
        </div>
      </div>
    </MainLayoutPreview>
  </BrowserPreview>
</template>

<style lang="scss" scoped>
.return-summary-preview {
  font-size: 14px;

  .subheader {
    font-weight: 900;
    margin-bottom: 10px;
  }

  .price {
    font-weight: 900;
    font-size: 16px;
  }

  .giftCardTitle {
    font-size: 12px;
    font-weight: 300;
  }

  .termsTitle {
    font-size: 12px;
    font-weight: 500;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 36px;
    font-size: 13px;
    background-color: #3356e4;
    color: #fff;
    margin-top: 20px;
  }

  .shippingFeeLabel {
    width: 250px;
    padding-left: 2px;
    border: solid #FDC014 3px;
  }

  .button-preview-container {
    width: 100px;
  }
}
</style>
