import { ref } from 'vue'
import { defineStore } from 'pinia'
import { ALERT_TYPES } from '@/constants'

export const useAlertStore = defineStore('alert', () => {
  const alerts = ref([])

  function info(message) {
    addAlert(message, ALERT_TYPES.INFO)
  }

  function success(message) {
    addAlert(message, ALERT_TYPES.SUCCESS)
  }

  function error(message) {
    addAlert(message, ALERT_TYPES.ERROR)
  }

  function warning(message) {
    addAlert(message, ALERT_TYPES.WARNING)
  }

  function addAlert(message, type) {
    alerts.value = alerts.value.concat({ message, type })
    // removes alert after 5 seconds
    setTimeout(() => { removeAlertByMessage(message) }, 5000)
  }

  function removeAlertByMessage(message) {
    const index = alerts.value.findIndex(alert => alert.message === message)
    if (index > -1) { removeAlertByIndex(index) }
  }

  function removeAlertByIndex(index) {
    alerts.value = alerts.value.toSpliced(index, 1)
  }

  function $reset() {
    alerts.value = []
  }

  return {
    alerts,
    info,
    success,
    error,
    warning,
    removeAlertByIndex,
    $reset
  }
})
