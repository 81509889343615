import { defaultTo } from 'lodash'
import {
  ASSETS_IMAGES_PATH,
  ASSETS_FONTS_PATH,
  DEFAULT_PORTAL_URL_SUFFIX,
  PORTAL_STATUS
} from '@/constants'

export function getPortalIdentifier({ values = {} }) {
  return values.identifier
}

export function getImagesLocation(portal) {
  return `${getPortalIdentifier(portal)}/${ASSETS_IMAGES_PATH}`
}

export function getFontsLocation(portal) {
  return `${getPortalIdentifier(portal)}/${ASSETS_FONTS_PATH}`
}

export function getPortalUrlSuffix() {
  return defaultTo(import.meta.env.VITE_PORTAL_URL_SUFFIX, DEFAULT_PORTAL_URL_SUFFIX)
}

export function getPortalUrl(portalUrlPrefix) {
  return `${portalUrlPrefix}.${getPortalUrlSuffix()}`
}

export function getPortalName(values = {}) {
  return values.settings?.retailer_info?.name || values.identifier
}

export function isReady({ values = {} }) {
  const { status } = values
  return status === PORTAL_STATUS.READY
}
