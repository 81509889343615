<script setup>
import { ref, toRaw, watch, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { buildFormState } from '@/utils/form'
import { returnReasonItemSchema } from '@/validation_schemas/portal/policies/return_reasons'
import { EXCHANGE_OFFERS } from '@/constants'
import InfoTooltip from '@/components/InfoTooltip.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldCheckbox from '@/components/FormFieldCheckbox.vue'
import FormFieldSelect from '@/components/FormFieldSelect.vue'
import FormFieldError from '@/components/FormFieldError.vue'
import { useUserStore } from '@/stores/user'

const { isCustomerUser } = storeToRefs(useUserStore())

const returnReasonEditableFieldPaths = computed(() => (
  isCustomerUser.value
    ? ['name', 'waive_shipping_fee_enabled']
    : ['*']
))

const props = defineProps({
  items: Array,
  disabled: Boolean
})

const emit = defineEmits(['add', 'update', 'remove'])

const fieldsMapping = {
  name: 'name',
  waiveShippingFeeEnabled: 'waive_shipping_fee_enabled',
  exchangeOffer: 'exchange_offer'
}

const exchangeOfferOptions = [
  { label: 'Size', value: EXCHANGE_OFFERS.SIZE },
  { label: 'Color', value: EXCHANGE_OFFERS.COLOR },
  { label: 'All sizes and colors', value: EXCHANGE_OFFERS.OTHER },
  { label: 'Re-order SKU', value:  EXCHANGE_OFFERS.DAMAGED },
  { label: 'N/A (refund)', value: EXCHANGE_OFFERS.REFUND }
]

const formItems = ref([])

buildFormItems(props.items)

watch(() => props.items, buildFormItems)

function buildFormItems(newItems) {
  formItems.value = (newItems || []).map(buildFormItem)
}

function buildFormItem(initialValues) {
  // refactor this in RX-6160
  const item = buildFormState({
    validationSchema: returnReasonItemSchema,
    initialValues,
    fieldsMapping,
    editableFieldPaths: returnReasonEditableFieldPaths.value
  })
  item.validateOnlyEditable()
  return item
}

function onChangeItem(index) {
  emit('update', index, toRaw(formItems.value[index].values))
}

function onClickRemove(index) {
  emit('remove', index)
}

const addForm = ref(buildFormState({
  validationSchema: returnReasonItemSchema,
  initialValues: isCustomerUser.value ? { exchange_offer: EXCHANGE_OFFERS.REFUND } : {},
  fieldsMapping,
  editableFieldPaths: returnReasonEditableFieldPaths.value
}))

function onClickAdd() {
  const { values, validateOnlyEditable, reset } = addForm.value

  if (validateOnlyEditable()) {
    emit('add', toRaw(values))
    reset()
  }
}
</script>

<template>
  <table class="table return-reasons-table">
    <thead>
      <tr>
        <th class="d-flex align-items-center">
          REASON *&nbsp;
          <InfoTooltip
            title="Reasons are displayed to your customers in the Returns Portal. Use your brand’s voice & tone to describe the reasons."
            placement="top"
          />
        </th>
        <th>WAIVE SHIPPING FEE</th>
        <th class="d-flex align-items-center">
          EXCHANGE OFFER *&nbsp;
          <InfoTooltip
            title="An exchange offer is the product variant that your customer is offered based on the reason they select."
            placement="top"
          />
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="addForm.fields.name"
            :disabled="disabled"
          />
          <FormFieldError :fieldModel="addForm.fields.name" />
        </td>
        <td>
          <div class="form-check">
            <FormFieldCheckbox
              class="form-check-input"
              :fieldModel="addForm.fields.waiveShippingFeeEnabled"
              :disabled="disabled"
            />
          </div>
        </td>
        <td>
          <FormFieldSelect
            class="form-select"
            :fieldModel="addForm.fields.exchangeOffer"
            :disabled="disabled"
          >
            <option :value="null">Select Exchange Offer</option>
            <option
              v-for="(option, index) in exchangeOfferOptions"
              :key="index"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </FormFieldSelect>
          <FormFieldError :fieldModel="addForm.fields.exchangeOffer" />
        </td>
        <td class="text-end">
          <button
            type="button"
            class="btn btn-outline-primary table-button"
            :disabled="disabled || !addForm.valid"
            @click="onClickAdd"
          >
            ADD
          </button>
        </td>
      </tr>
      <tr v-for="(formItem, index) in formItems" :key="index">
        <td>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="formItem.fields.name"
            :disabled="disabled"
            @input="onChangeItem(index)"
          />
          <FormFieldError :fieldModel="formItem.fields.name" />
        </td>
        <td>
          <div class="form-check">
            <FormFieldCheckbox
              class="form-check-input"
              :fieldModel="formItem.fields.waiveShippingFeeEnabled"
              :disabled="disabled"
              @change="onChangeItem(index)"
            />
          </div>
        </td>
        <td>
          <FormFieldSelect
            class="form-select"
            :fieldModel="formItem.fields.exchangeOffer"
            :disabled="disabled"
            @change="onChangeItem(index)"
          >
            <option :value="null">Select Exchange Offer</option>
            <option
              v-for="(option, index) in exchangeOfferOptions"
              :key="index"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </FormFieldSelect>
          <FormFieldError :fieldModel="formItem.fields.exchangeOffer" />
        </td>
        <td class="text-end">
          <button
            type="button"
            class="btn btn-primary-outline border-0 table-button"
            @click="onClickRemove(index)"
            :disabled="disabled"
          >
            <BootstrapIcon name="bs-icon-x-lg" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
.return-reasons-table {
  border-collapse: separate;
  border-spacing:0 10px;

  thead tr {
    box-shadow: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  tr {
    box-shadow: var(--bs-box-shadow);
    border: 2px solid #F0F0F0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-left-style: solid;
    }

    td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-right-style: solid;
      text-align: right;
      padding-right: 20px;
      width: 100px;
    }
  }

  td {
    margin-top: 20px;
    border: solid 1px;
    border-style: solid none solid none;
    border-color: inherit;
    padding: 10px;
    vertical-align: middle;
  }

  .table-button {
    min-width: 68px;
  }
}
</style>
