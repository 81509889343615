<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import { getFileUrl } from '@/utils/file'
import BrowserPreview from '@/components/customize/BrowserPreview.vue'
import MainLayoutPreview from '@/components/customize/MainLayoutPreview.vue'
import iconGiftCardSvg from '@/assets/images/icon-giftcard.svg'
import ButtonPreview from '@/components/customize/ButtonPreview.vue'

const { selectedPortal } = storeToRefs(usePortalsStore())

const logoUrl = computed(() => {
  const { fields } = selectedPortal.value
  if (fields.mainLogo.inputValue) {
    return getFileUrl(fields.mainLogo.inputValue)
  }
  return null
})

const bodyFontFamily = computed(() => {
  const { fields } = selectedPortal.value
  return fields.bodyBrandFontFamily.inputValue
    ? fields.bodyBrandFontFamily.inputValue
    : fields.bodyWebFontFamily.inputValue
})

const bodyColor = computed(() => {
  return selectedPortal.value.fields.bodyColor.inputValue
})

const mainHeaderFontFamily = computed(() => {
  const { fields } = selectedPortal.value

  if (fields.mainHeaderBrandFontFamily.inputValue) {
    return fields.mainHeaderBrandFontFamily.inputValue
  }

  return fields.mainHeaderWebFontFamily.inputValue || bodyFontFamily.value
})

const subHeaderFontFamily = computed(() => {
  const { fields } = selectedPortal.value

  if (fields.subHeaderBrandFontFamily.inputValue) {
    return fields.subHeaderBrandFontFamily.inputValue
  }

  return fields.subHeaderWebFontFamily.inputValue || bodyFontFamily.value
})

const headerColor = computed(() => {
  return selectedPortal.value.fields.headerColor.inputValue || bodyColor.value
})

const headlineText = computed(() => (
  selectedPortal.value.fields.instantGiftCardHeadline.inputValue
))

const subcopyText = computed(() => (
  selectedPortal.value.fields.instantGiftCardSubcopy.inputValue
))
</script>

<template>
  <BrowserPreview>
    <MainLayoutPreview>
      <div class="refund-preview d-flex flex-column align-items-center py-4">
        <div class="d-flex flex-column">
          <div
            class="header"
            :style="{
              'font-family': mainHeaderFontFamily,
              'color': headerColor
            }">
            How do you want your refund?
          </div>
          <div class="block d-flex flex-column align-items-center">
            <div class="block-header d-flex w-100">
              <div class="flex-fill">
                <div
                  class="subheader"
                  :style="{
                    'font-family': subHeaderFontFamily,
                    'color': headerColor
                  }"
                >
                {{ headlineText }}
                </div>
                <div v-html="subcopyText">
                </div>
              </div>
              <div class="d-flex flex-column align-items-end">
                <div class="price green">$20.00&nbsp;&nbsp;</div>
                <div class="mt-1">
                  <ButtonPreview
                    label="Choose"
                    type="primary"
                    state="active"
                    padding="8px 14px"
                  />
                </div>
              </div>
            </div>
            <div class="block-card-container">
              <div class="block-card d-flex flex-column align-items-center">
                <div class="block-card-logo-container w-100">
                  <img class="img-fluid" v-if="logoUrl" :src="logoUrl" alt="">
                </div>
                <div class="price green block-card-price">$20.00</div>
                <div class="block-card-footer w-100 d-flex align-items-center justify-content-between">
                  <div>Includes est. $20.00 order refund</div>
                  <img alt="" :src="iconGiftCardSvg" class="gift-card-icon">
                </div>
              </div>
              <div class="disclaimer-text">
                {{ selectedPortal.fields.optionalGiftCardDisclaimer.inputValue }}
              </div>
            </div>
          </div>
          <div class="block">
            <div class="block-header d-flex w-100">
              <div class="flex-fill">
                <div
                  class="subheader"
                  :style="{
                    'font-family': subHeaderFontFamily,
                    'color': headerColor
                  }"
                >
                  Original Payment Method
                </div>
                <div>Est. 2-3 days after original items are received</div>
              </div>
              <div class="d-flex flex-column align-items-end">
                <div class="price">$20.00&nbsp;&nbsp;</div>
                <div class="mt-1">
                  <ButtonPreview
                    label="Choose"
                    type="primary"
                    state="active"
                    padding="8px 14px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayoutPreview>
  </BrowserPreview>
</template>

<style lang="scss" scoped>
.refund-preview {
  font-size: 14px;

  .header {
    font-size: 28px;
    font-weight: 900;
    margin-bottom: 8px;
  }

  .subheader {
    font-size: 18px;
    font-weight: 500;
  }

  .price {
    font-weight: 900;
    font-size: 16px;

    &.green {
      color: #00812c;
    }
  }

  .block {
    width: 450px;
    background-color: #f6f6f6;
    padding: 12px;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-top: 16px;
    
    .block-header {
      margin-bottom: 16px;
    }

    .block-card-container {
      width: 300px;

      .block-card {
        background-color: #fff;
        width: 100%;
        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),
                    0 2px 2px 0 rgba(0,0,0,.14),
                    0 1px 5px 0 rgba(0,0,0,.12);
        border-radius: 8px;
        padding: 16px;
        padding-top: 8px;

        .block-card-logo-container {
          img {
            width: 96px;
          }
        }

        .block-card-price {
          font-size: 33px;
          padding: 12px;
        }

        .block-card-footer {
          font-size: 11px;
        }
      }

      .disclaimer-text {
        margin-top: 10px;
        font-size: 11px;
      }
    }
  }
}
</style>
